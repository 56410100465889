import * as React from 'react';
import { Routes, Route } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout";
import Layout from "./layouts/Layout";
import HomePage from "./pages/HomePage";
import OrganisationPage from "./pages/OrganisationPage";
import ChatCategoryPage from "./pages/ChatCategoryPage";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import PasswordResetPage from "./pages/PasswordRestePage";
import PaymentStatusPage from "./pages/PaymentStatusPage";
import AuthenticationStatusPage from "./pages/AuthenticationStatusPage";
import DatabasesPage from "./pages/DatabasesPage";
import LandingPage from "./pages/LandingPage";
import FilesPage from "./pages/FilesPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useTheme, ThemeProvider, createTheme, Theme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { Button } from '@mui/material';
import OrganisationSettings from './components/organisation/OrganisationSettings';
import OrganisationUsers from './components/organisation/OrganisationUsers';
import BillingComponent from './components/organisation/BillingComponent';
declare module '@mui/material/styles' {
  interface Palette {
    gradients: {
      primary: string;
      primaryHover: string;
      primaryActive: string;
    };
  }
  interface PaletteOptions {
    gradients?: {
      primary?: string;
      primaryHover?: string;
      primaryActive?: string;
    };
  }
}

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export function DarkModeToggle() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  return (
    <Box>
      <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Box>
  );
}

function App() {
  const [mode, setMode] = React.useState<'light' | 'dark'>(() => {
    const savedMode = localStorage.getItem('themeMode');
    return savedMode ? (savedMode as 'light' | 'dark') : 'light';
  });

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('themeMode', newMode);
          return newMode;
        });
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: '#7C3AED', // Purple color
            contrastText: '#FFFFFF',
          },
          secondary: {
            main: '#3B82F6', // Blue color
            contrastText: '#FFFFFF',
          },
          background: {
            default: mode === 'light' ? '#F3F4F6' : '#1F2937',
            paper: mode === 'light' ? '#FFFFFF' : '#374151',
          },
          text: {
            primary: mode === 'light' ? '#111827' : '#F9FAFB',
            secondary: mode === 'light' ? '#6B7280' : '#D1D5DB',
          },
          gradients: {
            primary: 'linear-gradient(45deg, #7C3AED 30%, #3B82F6 90%)',
            primaryHover: 'linear-gradient(45deg, #A78BFA 30%, #60A5FA 90%)',
            primaryActive: 'linear-gradient(45deg, #4C1D95 30%, #1E40AF 90%)',
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: '8px',
                textTransform: 'none',
                background: 'linear-gradient(45deg, #7C3AED 30%, #3B82F6 90%)',
                color: '#FFFFFF',
                '&:hover': {
                  background: 'linear-gradient(45deg, #A78BFA 30%, #60A5FA 90%)',
                },
                '&:active': {
                  background: 'linear-gradient(45deg, #4C1D95 30%, #1E40AF 90%)',
                },
              },
            },
          },
        },
      }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/auth/" element={<AuthLayout />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="reset-password" element={<PasswordResetPage />} />
          </Route>
          <Route path="/" element={<Layout />}>
            <Route path="/organisation/:orgId/category/:categoryIdParam/thread/:threadIdParam" element={<HomePage />} />
            <Route path="verify-email" element={<VerifyEmailPage />} />
          </Route>
          <Route path="/organisation/" element={<Layout />}>
            <Route path="settings" element={<OrganisationSettings />} />
            <Route path="users" element={<OrganisationUsers />} />
            <Route path="billing" element={<BillingComponent />} />
            <Route path="chat-category" element={<ChatCategoryPage />} />
            <Route path="databases" element={<DatabasesPage />} />
            <Route path="files" element={<FilesPage />} />
          </Route>
          <Route path="payment/:status" element={<PaymentStatusPage />} />
          <Route path="authentication/:status" element={<AuthenticationStatusPage />} />
          <Route path="home" element={<LandingPage />} />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
