import { useState } from "react";
import { TextField, Button, Typography, Box, Tooltip, IconButton, CircularProgress, Grid, Paper, Chip, Stack } from "@mui/material";
import { FaUpload, FaPlusCircle } from "react-icons/fa";
import { useApp, useAuth } from "../../hooks";
import { createDatabaseFromFileService, getDatabasesService, getGoogleAuthToken, get_upload_url } from "../../services/databases.service";
import DatabasesList from "./DatabasesList";
import useDrivePicker from 'react-google-drive-picker';
import { v4 as uuidv4 } from 'uuid';

const DatabaseContentForm = ({ setTabs }: any) => {
  const { state, dispatch }: any = useApp();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors]: any = useState({});
  const { auth }: any = useAuth();
  const [database, setDatabase] = useState({
    database_name: '',
    custom_instruction_1: ''
  });
  const [filesMeta, setFilesMeta]: any = useState([]);
  const [google_drive_files, setGoogleDriveFiles]: any = useState([]);
  const [is_google_drive_file, setIsGoogleDriveFile] = useState(false);
  const [filesData, setFilesData]: any = useState([]);
  const [error, setError] = useState('');
  const [customInstructions, setCustomInstructions] = useState(['']);

  const handledatabaseChange = (event: any) => {
    const { name, value } = event.target;
    const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{}`~;':"\\|,.<>\/?]+/;

    if (name === 'database_name' && specialCharRegex.test(value)) {
      console.log('Drive name should not contain any special characters');
      setErrors({ ...errors, [name]: 'Drive name should not contain any special characters' });
    } else {
      setErrors({ ...errors, [name]: '' });
      setDatabase({
        ...database,
        [name]: value
      });
    }
    setError('');
  };

  const handleCustomInstructionChange = (index: any, value: any) => {
    const newInstructions = [...customInstructions];
    newInstructions[index] = value.slice(0, 100);
    setCustomInstructions(newInstructions);
  };

  const addCustomInstructionField = () => {
    if (customInstructions.length < 3) {
      setCustomInstructions([...customInstructions, '']);
    }
  };

  const handleFileChange = async (event: any) => {
    setError('');
    try {
      const files = Array.from(event.target.files);
      const newFilesMeta: any = [];
      const newFilesData: any = [];
      let hasInvalidFile = false;

      if (files.length + filesMeta.length > 30) {
        setError('You can only upload a maximum of 30 files at a time.');
        return;
      }

      files.forEach((file: any) => {
        const fileType = file.name.split('.').slice(-1)[0];
        const fileName = file.name;

        if (fileType === 'pdf' || fileType === 'docx' || fileType === 'mp4') {
          newFilesMeta.push({
            type: fileType,
            name: fileName
          });
          newFilesData.push(file);
          if (fileType === 'mp4') {
            setCustomInstructions(['']);
          }
        } else {
          hasInvalidFile = true;
        }
      });

      if (hasInvalidFile) {
        setError('Invalid file format. Please select PDF, DOCX, or MP4 files only!');
      } else {
        setFilesMeta([...filesMeta, ...newFilesMeta]);
        setFilesData([...filesData, ...newFilesData]);
        setError('');
      }
    } catch (error) {
      setError('Invalid file format. Please select PDF, DOCX, or MP4 files only!');
    }
  };

  const handleRemoveFile = (index: number) => {
    const newFilesMeta = [...filesMeta];
    const newFilesData = [...filesData];
    newFilesMeta.splice(index, 1);
    newFilesData.splice(index, 1);
    setFilesMeta(newFilesMeta);
    setFilesData(newFilesData);
  };

  const handleRemoveGoogleDriveFile = (index: number) => {
    const newGoogleDriveFiles = [...google_drive_files];
    newGoogleDriveFiles.splice(index, 1);
    setGoogleDriveFiles(newGoogleDriveFiles);
  };

  const validateForm = (payload: any) => {
    const status = { isValid: true, errorMessage: 'Form validation failed!' };
    let tempErrors = {};

    if (!payload.database_name) {
      status.isValid = false;
      tempErrors = { ...tempErrors, database_name: 'Please enter database name!' };
    }
    if ((!is_google_drive_file && (filesMeta.length === 0))) {
      status.isValid = false;
      tempErrors = { ...tempErrors, category_file: 'Please select files!' };
    }
    setErrors(tempErrors);

    return status;
  };

  const onSave = async () => {
    setLoading(true);
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        show: true,
        title: 'Drive',
        message: 'We are working on creating your drive. This may take a few moments, please be patient.',
        type: 'progress',
        toastId: 'database-progress'
      }
    });
    try {
      const database_id = uuidv4();
      const payload: any = {
        database_name: database.database_name,
        organisation_id: state.selectedOrganisation._id,
        is_file_upload: true,
        custom_instructions: customInstructions.filter(instruction => instruction.trim() !== ''),
        is_google_drive_file: is_google_drive_file,
        database_id: database_id
      };
      if (!is_google_drive_file) {
        const { isValid } = validateForm(payload);
        if (isValid) {
          const fileIds = [];
          for (let i = 0; i < filesMeta.length; i++) {
            const file_id = uuidv4();
            const { upload_url_status, upload_url } = await get_upload_url(state.selectedOrganisation._id, database_id, null, filesMeta[i].type, file_id);
            console.log("Upload URL", upload_url);
            if (upload_url_status) {
              const response = await fetch(upload_url, {
                method: 'PUT',
                body: filesData[i],
                headers: {
                  'Content-Type': filesMeta[i].type ?? '',
                },
              });

              console.log("Upload Response", response);

              if (!response.ok) {
                dispatch({
                  type: 'SET_NOTIFICATION',
                  payload: {
                    show: true,
                    title: 'Drive',
                    message: 'Something went wrong. Please try again later.',
                    type: 'update',
                    toastId: 'database-progress',
                    updateType: 'error'
                  }
                });
                setLoading(false);
                return;
              } else {
                fileIds.push({ file_id: file_id, file_type: filesMeta[i].type, file_name: filesMeta[i].name, lastEditedUtc: new Date().toISOString() });
              }
            }
          }

          payload.file_ids = fileIds;

          const { status, message } = await createDatabaseFromFileService(payload);
          if (status) {
            setLoading(false);
            dispatch({
              type: 'SET_NOTIFICATION',
              payload: {
                show: true,
                title: 'Drive',
                message: 'Drive created successfully',
                type: 'update',
                toastId: 'database-progress',
                updateType: 'success'
              }
            });
            if (state.selectedOrganisation?._id) {
              const databases = await getDatabasesService(state.selectedOrganisation._id, null, null, null, null);
              dispatch({ type: 'SET_DATABASES', payload: databases.response });
              setTabs({ name: 'Drives', component: <DatabasesList setTabs={setTabs} /> });
            }
          } else {
            dispatch({
              type: 'SET_NOTIFICATION',
              payload: {
                show: true,
                title: 'Drive',
                message: message,
                type: 'update',
                toastId: 'database-progress',
                updateType: 'error'
              }
            });
            setLoading(false);
          }
        } else {
          dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              show: true,
              title: 'Drive',
              message: 'Form validation failed!',
              type: 'update',
              toastId: 'database-progress',
              updateType: 'error'
            }
          });
          setLoading(false);
        }
      } else {
        let fileIds = [];
        for (let i = 0; i < google_drive_files.length; i++) {
          fileIds.push({ file_id: google_drive_files[i].id, file_type: google_drive_files[i].mimeType, file_name: google_drive_files[i].name, lastEditedUtc: google_drive_files[i].lastEditedUtc });
        }
        payload.file_ids = fileIds;
        const { status, message } = await createDatabaseFromFileService(payload);
        if (status) {
          setLoading(false);
          dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              show: true,
              title: 'Drive',
              message: 'Drive created successfully',
              type: 'update',
              toastId: 'database-progress',
              updateType: 'success'
            }
          });
          if (state.selectedOrganisation?._id) {
            const databases = await getDatabasesService(state.selectedOrganisation._id, null, null, null, null);
            dispatch({ type: 'SET_DATABASES', payload: databases.response });
            setTabs({ name: 'Drives', component: <DatabasesList setTabs={setTabs} /> });
          }
        } else {
          dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              show: true,
              title: 'Drive',
              message: message,
              type: 'update',
              toastId: 'database-progress',
              updateType: 'error'
            }
          });
          setLoading(false);
        }
      }
    } catch (error) {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          show: true,
          title: 'Drive',
          message: 'Something went wrong. Please try again later.',
          type: 'update',
          toastId: 'database-progress',
          updateType: 'error'
        }
      });
      setLoading(false);
    }
  };

  const setStatus = (message: any, type: any) => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: { show: true, title: 'Drive', message: message, type: type }
    });
  };

  const [openPicker] = useDrivePicker();

  const handleOpenPicker = () => {
    getGoogleAuthToken(auth.user.uid, state.selectedOrganisation._id).then((token) => {
      if (!token) {
        return;
      }
      const pickerConfig: any = {
        clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
        developerKey: process.env.REACT_APP_GOOGLE_OAUTH_DEVELOPER_KEY,
        viewId: 'DOCS',
        viewMimeTypes: 'application/vnd.google-apps.document,application/vnd.google-apps.spreadsheet,application/pdf,application/vnd.google-apps.folder',
        token: token,
        appId: process.env.REACT_APP_GOOGLE_OAUTH_APP_ID,
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        setIncludeFolders: true,
        multiselect: true,
        setSelectFolderEnabled: false,
        navHidden: false,
        enableFeatureViews: true,
        callbackFunction: (data: any) => {
          const elements = Array.from(
            document.getElementsByClassName(
              'picker-dialog'
            ) as HTMLCollectionOf<HTMLElement>
          );
          for (let i = 0; i < elements.length; i++) {
            elements[i].style.zIndex = '2000';
          }
          if (data.action === 'picked') {
            console.log(data);
            onSelectFileFromList(data.docs);
          }
        },
      };
      openPicker(pickerConfig);
    });
  };

  const onSelectFileFromList = (google_docs: any) => {
    if (google_docs.length + filesMeta.length > 30) {
      setError('You can only upload a maximum of 30 files at a time.');
      return;
    }

    let files_meta: any = [];
    for (let i = 0; i < google_docs.length; i++) {
      files_meta.push({
        type: google_docs[i].mimeType,
        name: google_docs[i].name
      });
    }
    setFilesMeta([...filesMeta, ...files_meta]);
    setGoogleDriveFiles([...google_drive_files, ...google_docs]);
    setIsGoogleDriveFile(true);
    setError('');
    setCustomInstructions([]);
    google_docs.forEach((doc: any) => {
      if (doc.mimeType === 'video/mp4') {
        setCustomInstructions(['']);
      }
    });
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 2}}>
      <Typography variant="h5" gutterBottom>
        Create New Drive
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        <TextField
          label="Drive Name"
          fullWidth
          margin="normal"
          name="database_name"
          value={database.database_name}
          onChange={handledatabaseChange}
          error={!!errors['database_name']}
          helperText={errors['database_name']}
        />
         <Box mt={2} mb={2} width="100%">
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6}>
            <input
              accept=".pdf,.docx,.mp4"
              style={{ display: 'none' }}
              id="file-upload"
              multiple
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<FaUpload />}
                fullWidth
              >
                Browse in Computer
              </Button>
            </label>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Tooltip title="Currently, you can select multiple files (docs, pdf, etc.) with this feature. In the future, we will allow you to select an entire folder.">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<FaUpload />}
                onClick={handleOpenPicker}
                fullWidth
                sx={{ mt: { xs: 2, sm: 0 } }} 
              >
                Select from Google Drive
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
        <Stack direction="row" spacing={1} flexWrap="wrap">
          {filesMeta.map((file: any, index: any) => (
            <Chip
              key={index}
              label={file.name}
              onDelete={() => handleRemoveFile(index)}
              color="primary"
              variant="outlined"
              sx={{ mb: 1 }}
            />
          ))}
          {google_drive_files.map((file: any, index: any) => (
            <Chip
              key={index}
              label={file.name}
              onDelete={() => handleRemoveGoogleDriveFile(index)}
              color="secondary"
              variant="outlined"
              sx={{ mb: 1 }}
            />
          ))}
        </Stack>
        {filesMeta.some((file: any) => file.type === 'mp4') && customInstructions.map((instruction, index) => (
          <TextField
            key={index}
            label={`Custom Instruction ${index + 1}`}
            fullWidth
            margin="normal"
            value={instruction}
            onChange={(e: any) => handleCustomInstructionChange(index, e.target.value)}
          />
        ))}
        {filesMeta.some((file: any) => file.type === 'mp4') && customInstructions.length < 3 && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<FaPlusCircle />}
            onClick={addCustomInstructionField}
            sx={{ mt: 2 }}
          >
            Add Custom Instruction
          </Button>
        )}
        {errors['category_file'] && (
          <Typography color="error" variant="body2">
            {errors['category_file']}
          </Typography>
        )}
        <Box mt={4}>
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            disabled={loading}
            fullWidth
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default DatabaseContentForm;
