const CustomProgressBar = ({ now, max }: any) => {
  // Calculate the width of the filled part of the progress bar


  const widthFilled = `${((now <= 0) ? 0 : now / max) * 100}%`;

  const label = `${now}/${max}`;

  const progressBarStyle: React.CSSProperties = {
    height: '25px',
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: '50px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    position: 'relative',
    overflow: 'hidden',
  };

  const progressFillStyle: React.CSSProperties = {
    height: '100%',
    width: widthFilled,
    background: 'linear-gradient(to right, #c084fc, #60a5fa)',
    transition: 'width 1s ease-in-out',
    borderRadius: 'inherit',
  };

  const labelStyle: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: now <= 0 ? 'rgb(244, 67, 54)' : 'rgb(30, 64, 175)',
    fontWeight: 'bold',
    fontSize: '1em',
    letterSpacing: '1px'
};

  return (
    <div style={progressBarStyle}>
      <div  style={progressFillStyle} />
      <span style={labelStyle}>{label}</span>
    </div>
  );
};

export default CustomProgressBar;
