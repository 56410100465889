import React, { useState } from "react";
import { useApp } from "../../hooks";
import { createCategoryFromFileService, editCategoryService, getAllChatCategoryService } from "../../services/category.service";
import ChatCategoryList from "./ChatCategoryList";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  OutlinedInput,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ChatContentForm = ({ initialCategoryName = '', initialCategoryId = '', initialCategoryInstruction = 'As an AI, your purpose is to help the user using all the tools and databases provided.', initialDatabaseIds = [] }: any) => {
  const { state, dispatch }: any = useApp();
  const theme: Theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [category, setCategory] = useState({
    category_name: initialCategoryName,
    category_id: initialCategoryId,
    category_instruction: initialCategoryInstruction
  });
  const [error, setError] = useState('');
  const [databaseIds, setDatabaseIds] = useState(initialDatabaseIds);

  const handleCategoryChange = (event: any) => {
    setErrors({ ...errors, [event.target.name]: '' });
    setCategory({
      ...category,
      [event.target.name]: event.target.value
    });
    setError('');
  };

  const handleDatabaseChange = (event: any) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    if (selectedValues.length <= 20) {
      setDatabaseIds(selectedValues);
    } else {
      dispatch({ type: 'SET_NOTIFICATION', payload: { show: true, title: 'Drive Selection', message: 'You can only select a maximum of 20 databases.', type: 'warn' } });
    }
  };

  const validateForm = (payload: any) => {
    const status = { isValid: true, errorMessage: 'Oops! There seems to be an issue with your form submission.' };
    let tempErrors = {};

    if (!payload.category_name) {
      status.isValid = false;
      tempErrors = { ...tempErrors, category_name: 'Chat Topic name is required. Please provide one.' };
    }
    if (!payload.category_instruction) {
      status.isValid = false;
      tempErrors = { ...tempErrors, category_instruction: 'Chat Topic instruction is required. Please provide one.' };
    }
    if (payload.category_instruction.length < 10 || category.category_instruction.split(' ').length > 500) {
      status.isValid = false;
      tempErrors = { ...tempErrors, category_instruction: 'Instruction length must be between 10 and 500 characters.' };
    }
    if (payload.databaseIds.length === 0) {
      status.isValid = false;
      tempErrors = { ...tempErrors, databaseIds: 'At least one database must be selected.' };
    }
    setErrors(tempErrors);
    return status;
  };

  const onSave = async () => {
    setLoading(true);
    try {
      const payload = {
        category_name: category.category_name,
        category_instruction: category.category_instruction,
        organisation_id: state.selectedOrganisation._id,
        databaseIds: databaseIds,
        category_id: category.category_id,
      };

      const { isValid } = validateForm(payload);
      if (isValid) {
        let status, message;

        if (category.category_id) {
          ({ status, message } = await editCategoryService(payload));
        } else {
          ({ status, message } = await createCategoryFromFileService(payload));
        }

        if (status) {
          setLoading(false);
          setStatus("Chat Topic saved", 'success');
          if (state.selectedOrganisation._id) {
            const categories = await getAllChatCategoryService(state.selectedOrganisation._id);
            dispatch({ type: 'SET_USER_CHAT_CATEGORIES', payload: categories || [] });
            if (categories.length > 0) {
              dispatch({ type: 'SET_USER_ACTIVE_CHAT_CATEGORY', payload: categories[0] || {} });
            }
          }
        } else {
          setLoading(false);
          setError(message);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError('Something went wrong. Please try again');
    }
  };

  const setStatus = (message: string, type: string) => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: { show: true, title: 'Chat Topic Update', message, type }
    });
  };

  return (
    <Box sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
      <Typography variant="h6" gutterBottom>
        Chat Topic Form
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        <TextField
          fullWidth
          label="Chat Topic Name"
          id="category_name"
          name="category_name"
          value={category.category_name}
          onChange={handleCategoryChange}
          error={!!errors['category_name']}
          helperText={errors['category_name']}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Instruction"
          id="category_instruction"
          name="category_instruction"
          value={category.category_instruction}
          onChange={handleCategoryChange}
          error={!!errors['category_instruction']}
          helperText={errors['category_instruction']}
          margin="normal"
          multiline
          rows={4}
        />
        <FormControl fullWidth margin="normal" error={!!errors['databaseIds']}>
          <InputLabel id="database-select-label">Databases</InputLabel>
          <Select
            labelId="database-select-label"
            id="database-select"
            multiple
            value={databaseIds}
            onChange={handleDatabaseChange}
            input={<OutlinedInput id="select-multiple-chip" label="Databases" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value: any) => (
                  <Chip key={value} label={state.databases.find((database: any) => database.database_id === value)?.database_name} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {state.databases.map((database: any) => (
              <MenuItem
                key={database.database_id}
                value={database.database_id}
                style={{
                  fontWeight: databaseIds.indexOf(database.database_id) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
                }}
              >
                {database.database_name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors['databaseIds']}</FormHelperText>
        </FormControl>
        {error && <Typography color="error">{error}</Typography>}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            disabled={loading}
            startIcon={loading && <CircularProgress size={20} />}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatContentForm;