
import styled from '@emotion/styled';
import { useCallback } from 'react';
import { Link, useOutlet } from "react-router-dom";
import { loadSlim } from "tsparticles-slim";
import Particles from "react-tsparticles";
import logo from '../assets/logos/logo.png';

import { keyframes } from 'styled-components';
import { AiOutlineArrowLeft } from 'react-icons/ai';

const AuthLayoutContainer = styled.div`
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const BackButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  color: black;
  background: white;
  border: none;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: scale(0.9);
  }
`;

const AuthLayout = () => {

    
    const outlet = useOutlet();
    const particlesInit = useCallback(async (engine: any) => {
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: any) => {
        await console.log(container);
    }, []);

  return (
    <AuthLayoutContainer>
       <Particles
  id="tsparticles"
  init={particlesInit}
  loaded={particlesLoaded}
  options={{
    background: {
        color: {
            value: "#fff",
        },
    },
    fpsLimit: 60,
    interactivity: {
        events: {
            onClick: {
                enable: true,
                mode: "repulse",
            },
            onHover: {
                enable: true,
                mode: "grab", 
            },
            resize: true,
        },
        modes: {
            repulse: {
                distance: 100, 
                duration: 0.8, 
            },
            bubble: {
                distance: 250,
                size: 12,
                duration: 1.5,
                opacity: 0.8, 
            },
        },
    },
    particles: {
        color: {
            value: ["#c70039", "#4361ee", "#fff"],
        },
        links: {
            color: "#000",
            distance: 150,
            enable: true,
            opacity: 0.2,
            width: 0.8,
        },
        move: {
            direction: "bottom",
            enable: true,
            outModes: {
                default: "out",
            },
            random: true,  
            speed: 1,  
            straight: false,
        },
        number: {
            density: {
                enable: true,
                area: 800,
            },
            value: 100,
        },
        opacity: {
            value: { min: 0.1, max: 0.6 }, 
        },
        shape: {
            type: "circle",
        },
        size: {
            value: { min: 1, max: 3 },
        },
    },
    detectRetina: true,
  }}
/>
<BackButton>
    <Link to="https://datalinker.ai/">
      <AiOutlineArrowLeft size={20} />
    </Link>
    </BackButton>
       <div   style={{ position: 'relative', border: '1px solid #a855f7' }} className="container max-w-screen-md mx-auto overflow-hidden rounded-lg m-10 shadow-lg bg-white">
        
        <div className="auth-container-sidebar-left hidden md:flex mt-4 bg-white">
          <div className="w-full flex flex-col">

            <div className="w-full  flex items-center justify-center mt-4">
                <img src={logo} width={350} alt="DatalinkerAI" />
                {/* <div className="absolute top-0 right-0 bg-red-500 text-white text-xs py-1 px-2 rounded-bl-md">
                    BETA
                </div> */}
            </div>
          </div>
          {/* <hr style={{ color: '#555' }}/> */}
        </div>
        <div className="auth-container-sidebar-right bg-white">
          {outlet}
        </div>
      </div>
    </AuthLayoutContainer>
  );
};

export default AuthLayout;