import axiosApiInstance from "./request"

interface IOrganisationPayload {
  name: string
}

const getOrganisationService = async ( organisationId: string) => {
  try {
    const response = await axiosApiInstance.post('v1/organisation/info', {organisation_id: organisationId})
    return response.data.data
  } catch (error) {
    return {}
  }
}

const createOrganisationService = async (paylaod: IOrganisationPayload) => {
  try {
    const response = await axiosApiInstance.post('v1/organisation/create', paylaod)
    console.log(response)
    return response.data
  } catch (error) {
    return null
  }
}

const updateOrganisationService = async (paylaod: any) => {
  try {
    const response = await axiosApiInstance.post('v1/organisation/edit', paylaod);
    return response.data
  } catch (error) {
    return null
  }
}

const updateUserRoleService = async (paylaod: any) => {
  try {
    const response = await axiosApiInstance.post('v1/organisation/edit-member', paylaod);
    console.log(response)
    if (response.status === 200) {
      return { status: true, message: 'User role updated.' }
    } else {
      return { status: false, message: 'Something went wrong!' }
    }
  } catch (error) {
    return { status: false, message: 'Something went wrong!' }
  }
}

const inviteUserService = async (email: string, organisationId: string, role: string) => {
  const response = { status: true, message: '' }
  try {
    const paylaod = {
      org_id: organisationId,
      email: email,
      role: role
    }
    await axiosApiInstance.post('v1/organisation/add-member', paylaod)
  } catch (error: any) {
    const message = error?.response?.data?.data?.message || "Something went wrong!"
    response.status = false
    response.message = message
  }
  return response
}

const updateZapierTokenService = async (zapierToken: string, organisationId: string, uid: string) => {
  try {
    const response = await axiosApiInstance.post('v2/zapier/update-zapier-key', { zapier_api_key: zapierToken, org_id: organisationId, uid: uid })
    return response
  } catch (error) {
    return {}
  }
}

const get_user_invitation_link = async (organisationId: string) => {
  try {
    const response = await axiosApiInstance.post('v1/organisation/generate-invite-link', { org_id: organisationId })
    return response.data.invite_link
  } catch (error) {
    return {}
  }
}

const send_join_request = (token: string, user_id: string ) => {
  try {
    const response = axiosApiInstance.get('v1/organisation/register-member', { params: {join_request: token, user_id } } )
    return response
  } catch (error) {
    return {}
  }
}

const accept_join_request = async (user_id: string, org_id: string) => {
  try {
    const response = await axiosApiInstance.post('v1/organisation/add-member/accept', { user_id, org_id })
    return response
  } catch (error) {
    return {}
  }
}

const reject_join_request = async (user_id: string, org_id: string) => {
  try {
    const response = await axiosApiInstance.post('v1/organisation/add-member/reject', { user_id, org_id })
    return response
  } catch (error) {
    return {}
  }
}

const get_all_pending_requests = async (org_id: string) => {
  try {
    const response = await axiosApiInstance.post('v1/organisation/add-member/pending', { org_id })
    return response
  } catch (error) {
    return {}
  }
}

const removeUserFromOrgService = async (userid: string, organisationId: string) => {
  const response = { status: true, message: '' }
  try {
    const paylaod = {
      org_id: organisationId,
      user_id: userid
    }
    await axiosApiInstance.post('v1/organisation/remove-member', paylaod)
  } catch (error: any) {
    const message = error?.response?.data?.data?.message || "Something went wrong!"
    response.status = false
    response.message = message
  }
  return response
}

export {
  getOrganisationService,
  createOrganisationService,
  updateOrganisationService,
  inviteUserService,
  removeUserFromOrgService,
  updateUserRoleService,
  updateZapierTokenService,
  get_user_invitation_link,
  send_join_request,
  accept_join_request,
  reject_join_request,
  get_all_pending_requests
}