import { motion } from 'framer-motion';
import { useState } from 'react';

interface EditDatabaseDialogProps {
    databaseId: string;
    initialName: string;
    initialUsedVersion: number;
    initialVersion: number;
    versions: any;
    onConfirm: (database_id: string, database_name: string, database_version: number) => void;
    onCancel: () => void;
}

const EditDatabaseDialog = ({ databaseId, initialName, initialUsedVersion, initialVersion, versions, onConfirm, onCancel }: EditDatabaseDialogProps) => {
    const [name, setName] = useState(initialName);
    const [database_version, setDatabaseVersion] = useState(initialUsedVersion);

    const handleConfirm = () => {
        onConfirm(databaseId, name, database_version);
    };

    const versionOptions = Array.from({length: initialVersion}, (_, i) => i + 1);

    return (
        <motion.div 
            initial={{ opacity: 0 }} 
            animate={{ opacity: 1 }} 
            transition={{ duration: 0.5 }}
            className="p-8 bg-white rounded-lg shadow-lg"
        >
            <div className="text-center mb-6">
                <h2 className="text-2xl font-semibold text-gray-700">Edit Drive</h2>
            </div>
            <div className="mt-6 flex flex-col space-y-4">
                <label className="text-gray-600 font-medium">Drive Name</label>
                <input 
                    type="text" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                    className="border-2 border-gray-300 p-2 rounded-md text-gray-700"
                    onKeyPress={(e) => {
                        const regex = /[!@#$%^&*()_+\-=\[\]{};'`~:"\\|,.<>\/?]+/;
                        if(regex.test(e.key)){
                            e.preventDefault();
                        }
                    }}
                />
                {versionOptions.length > 0 && (
                    <>
                        <label className="text-gray-600 font-medium">Drive Version</label>
                        <select 
                            value={database_version} 
                            onChange={(e) => setDatabaseVersion(Number(e.target.value))} 
                            className="border-2 border-gray-300 p-2 rounded-md text-gray-700"
                        >
                            {versionOptions.map(version => (
                                <option key={version} value={version}>
                                    {`Version ${version} - Created at ${new Date(versions[version]['updated_at']).toLocaleString()}`}
                                </option>
                            ))}
                        </select>
                    </>
                )}
            </div>
            <div className="mt-6 flex justify-center space-x-4">
                <motion.button 
                    whileHover={{ scale: 1.05 }} 
                    whileTap={{ scale: 0.95 }} 
                    className="inline-flex items-center px-6 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={handleConfirm}
                >
                    Confirm
                </motion.button>
                <motion.button 
                    whileHover={{ scale: 1.05 }} 
                    whileTap={{ scale: 0.95 }} 
                    className="inline-flex items-center px-6 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={onCancel}
                >
                    Cancel
                </motion.button>
            </div>
        </motion.div>
    );
}

export default EditDatabaseDialog;