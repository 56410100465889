import { useState } from "react"
import { useAuth } from "../hooks"
import { Link, useNavigate } from "react-router-dom";
import { loginService } from "../services/auth.service";
import Button from "../components/shared/Button";
import { useSpring, animated } from 'react-spring';

const LoginPage = () => {
    const { auth, setAuth }: any = useAuth();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [user, setUser] = useState({
        email: '',
        password: '',
    })

    const [error, setError] = useState("")

    const handleLogin = async () => {
		setLoading(true)
		try {
			if(!user.email || !user.password) {
				setError('Please enter your email address and password to log in.')
				setLoading(false)
				return false
			}
			const response: any = await loginService(user)
			if (response.status) {
				let { displayName, email, emailVerified, localId, idToken, refreshToken } = response?.data
				window.localStorage.setItem('idToken', idToken)
				window.localStorage.setItem('refreshToken', refreshToken)
				if (!displayName) displayName = email.split('@')[0]
				const user = { uid: localId, email: email, displayName: displayName, emailVerified: emailVerified }
				setAuth({ ...auth, user: user })
				navigate("/organisation/${selectedOrganisation._id}/category/1/thread/1")
			} else {
				setLoading(false)
				setError(response.messsage)
			}
		} catch (error) {
			setLoading(false)
		}

	}

    const handleChange = (event: any) => {
        setError('')
        setUser({
            ...user,
            [event.target.name]: event.target.value
        })
    }

    const fade = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        },
        delay: 500
    });

	const inputStyle = "text-sm appearance-none rounded-lg w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10 shadow-md";
    const labelStyle = "block text-gray-700 text-sm font-semibold mb-2";


    return (
        <div className="max-w-md mx-auto xl:max-w-3xl h-full flex bg-white overflow-hidden">
            <animated.div style={fade} className="w-full p-6">
			<h1 className=" text-2xl font-bold">Sign in to your account</h1>
                <div>
                    <span className="text-gray-600 text-sm"> Don't have an account?</span>
                    <Link className="text-gray-700 text-sm font-semibold ml-2" to="/auth/register">Sign up</Link>
                </div>
                <div className="mb-4 mt-6">
                    <label className={labelStyle} htmlFor="email">Email</label>
                    <input className={inputStyle} id="email" name="email" type="text" onChange={handleChange} placeholder="Your email address" />
                </div>
                <div className="mb-6 mt-6">
                    <label className={labelStyle} htmlFor="password">Password</label>
                    <input className={inputStyle} id="password" name="password" type="password" onChange={handleChange} placeholder="Your password" />
                    <Link className="inline-block align-baseline text-sm text-gray-600 hover:text-gray-800 p-2" to="/auth/reset-password">Forgot Password?</Link>
                </div>
                <div className="mb-6 mt-6">
                    {error && (<span className="text-red-600">{error}</span>)}
                </div>
                <div className="flex w-full mt-8">
    <Button 
        label="Sign In" 
        type="button" 
        loading={loading} 
        onClick={handleLogin} 
        className="w-full rounded-lg bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 text-white font-semibold py-2 px-4 border border-transparent shadow-sm transition duration-200 ease-in-out"
    />
</div>
            </animated.div>
        </div>
    )
}

export default LoginPage