import { useEffect } from "react"
import { useApp } from "../hooks"
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomCloseButton = ({ closeToast }: any) => (
    <button onClick={closeToast} style={{ color: 'red', background: 'transparent', border: 'none' }}>X</button>
);

const closeNotification = (id: any) => {
	return () => toast.dismiss(id);
}

const NotificationModel = () => {
    const { state, dispatch }: any = useApp();
    const { show, title, message, type, toastId, updateType } = state.notification;
	const uniqueId = toastId ?? Math.random().toString(36).substr(2, 9);
    const toastOptions = {
        className: 'toast-custom-class',
		toastId:uniqueId,
        progressClassName: 'toast-progress-custom-class',
        bodyClassName: 'toast-body-custom-class',
        closeButton: <CustomCloseButton closeToast={closeNotification(uniqueId)} />,
        style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            boxShadow: '2px 2px 20px rgba(0, 0, 0, 0.2)',
            fontFamily: 'Arial, sans-serif',
            fontSize: '16px',
        },
		// update transition animation
		transition: Slide
    };

    useEffect(() => {
        if (show) {
            switch(type) {
                case 'error':
                    toast.error(message, toastOptions);
                    break;
                case 'success':
                    console.log('NotificationModel', message);
                    toast.success(message, toastOptions); 
                    break;
                case 'warning':
                    toast.warning(message, toastOptions);
                    break;
				case 'info':
					toast.info(message, toastOptions);
					break;
                case 'progress':
                    toast.loading(message, toastOptions);
                    break;
				case 'dismiss':
					toast.dismiss(uniqueId);
					break;
				case 'update':
					console.log("updateType", updateType)
					toast.update(uniqueId, { render: message, type: updateType, autoClose: 5000 , isLoading: false,  ...toastOptions });
					break;
                default:
                    toast(message, toastOptions);
            }

            setTimeout(handleClose, 5000);
        }
    }, [show])

    const handleClose = () => {
        dispatch({
            type: 'SET_NOTIFICATION',
            payload: { show: false, title: '', message: '', type: '' }
        })
    }

    return null;
}

export default NotificationModel;