import { FC, ReactNode, useEffect, useReducer, useState } from "react";
import { AppContext, AuthContext } from "../contexts";
import { initialState } from "../redux/initialState";
import { appReducer } from "../redux/reducers";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks";
import { getUserService } from "../services/auth.service";
import Loader from "../components/shared/Loader";
import { getGeneralThreads } from "../services/chat.service";

interface AuthProps {
	children?: ReactNode;
}

interface AppProps {
	children?: ReactNode;
}

const AuthProvider: FC<AuthProps> = ({ children }) => {
	const [auth, setAuth] = useState({})

	return (
		<AuthContext.Provider value={{ auth: auth, setAuth: setAuth }}>
			{children}
		</AuthContext.Provider>
	)
}

const AppProvider: FC<AppProps> = ({ children }) => {
	const [loading, setLoading] = useState(true)
	const [state, dispatch] = useReducer(appReducer, initialState);
	const { auth, setAuth }: any = useAuth()
	const navigate = useNavigate()
	const location = useLocation()

	const logoutUser = () => {
		window.localStorage.removeItem('idToken');
		window.localStorage.removeItem('refreshToken');
		window.location.href = '/auth/login'
	}

	useEffect(() => {
	try {
		const urlParams = new URLSearchParams(window.location.search);
		const joinRequestToken = urlParams.get("join_request");
		if (joinRequestToken) {
		localStorage.setItem("join_request", joinRequestToken);
		}
		const idToken = localStorage.getItem("idToken");
		if (!idToken) logoutUser();
		const refreshUserSession = localStorage.getItem('refresh_user_session');
		console.log('refreshUserSession', refreshUserSession)
		if (!auth?.user?.organisations || !auth.user.uid || refreshUserSession) {
			console.log('setUserData')
			setUserData();
			localStorage.removeItem('refresh_user_session');
		}
	} catch (error) {
		window.localStorage.removeItem('idToken');
		window.localStorage.removeItem('refreshToken');
		window.localStorage.removeItem('pusherTransportTLS');
		navigate("/auth/login");
	}
	}, [auth]);

	useEffect(() => {
		if (state.selectedOrganisation?._id) {
			console.log('state.selectedOrganisation', state.selectedOrganisation)
			for (const c of auth.user.categories) {
				console.log('c', c)
				if (c[state.selectedOrganisation._id]) {
					dispatch({ type: 'SET_USER_CHAT_CATEGORIES', payload: c[state.selectedOrganisation._id] || [] })
					dispatch({ type: 'SET_USER_ACTIVE_CHAT_CATEGORY', payload: c[state.selectedOrganisation._id][0] } || {})
				}
			}
			getGeneralThreads().then((res: any) => {
				dispatch({
				   type: 'SET_GENERAL_THREADS',
				   payload: res
				 });
			});
		}
	}, [state.selectedOrganisation])

	useEffect(() => {
		if (state.selectedOrganisation?._id) {
			for (const c of auth.user.zapier_actions) {
				if (c[state.selectedOrganisation._id]) {
					dispatch({ type: 'SET_ZAPIER_ACTIONS', payload: c[state.selectedOrganisation._id] || [] })
				}
			}
		}
	}, [state.selectedOrganisation])

	const setUserData = async () => {
		const { data, status }: any = await getUserService()
		if (status) {
			if (data.organisations.length === 0) {
				dispatch({ type: 'IS_ORGANISATION_NOTEXIST', payload: true })
			} else {
				await setOrganisationDetail(data.organisations, data.uid)
			}
		} else {
			logoutUser()
		}
		if (!data?.displayName) data['displayName'] = data.email.split('@')[0]
		setAuth({ ...auth, user: data })
	}

	useEffect(() => {
		if (state.organisations.length > 0) {
			setLoading(false)
			if(state.selectedOrganisation && location.pathname === '/' ) {
				navigate(`/organisation/${state.selectedOrganisation._id}/category/1/thread/1`)
			}
		} else if(state.isOrganisationNotExist) {
			setLoading(false)
		}
	}, [state.organisations, state.isOrganisationNotExist]);

	const setOrganisationDetail = async (organisations: any, user_id: string) => {
		let activeOrg = {};
		for (const org of organisations) {
			const member = org.members.find((om: any) => om.user_id === user_id);
			console.log("Org : ", org);
			if (org.subscription_status === "active") {
			activeOrg = org;
			break;
			} else if (member.role === "owner") {
			activeOrg = org;
			} else {
			activeOrg = organisations[0];
			}
		}
		await Promise.all([
			dispatch({ type: 'SET_ORGANISATIONS', payload: organisations }),
			dispatch({ type: 'SET_ACTIVE_ORGANISATION', payload: activeOrg }),
		])
	}

	

	return (
		<AppContext.Provider value={{ state: state, dispatch: dispatch, setUserData: setUserData }}>
			{
				loading ? <Loader /> : children
			}
		</AppContext.Provider>
	)
}

export {
	AuthProvider,
	AppProvider
}