import { useContext } from "react";
import { AppContext, AuthContext } from "../contexts";

const useAuth = () => {
	const auth = useContext(AuthContext);
	if (!auth) {
		throw new Error( "AuthContext must be used within the AuthProvider");
	}
	return auth;
};

const useApp = () => {
	const app = useContext(AppContext);
	if (!app) {
		throw new Error( "AppContext must be used within the AppProvider");
	}
	return app;
};

export {
  useAuth,
  useApp
}