import Modal from '@mui/material/Modal';
import { useApp } from '../../hooks';
import OrganisationCreateForm from './OrganisationCreateForm';

const OrganisationCreateModel = () => {
  const { state }: any = useApp()

  return (
    <Modal open={state.isOrganisationNotExist}>
    <div className="p-8">
      <h1 className='text-xl font-bold'>Create your organisation</h1>
      <div className='mt-8'>
       <OrganisationCreateForm action="create" />
      </div>
    </div>
  </Modal>
  )
}

export default OrganisationCreateModel