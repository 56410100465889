import Modal from '@mui/material/Modal';
import { useApp } from '../hooks';

const ConformationModel = () => {
  const { state, dispatch }: any = useApp();
  const { show, onYes, heading, title } = state.conformation

  const onSelectNo = () => {
    dispatch({
      type: 'SHOW_CONFIRMATION', 
      payload: {
        show: false,
        onYes: null,
        onNo: null,
        heading:'',
        title: ''
      }
    })
  }

  return (
    <Modal open={show} >
    <div className="p-8 text-center">
      <h1>{heading}</h1>
      <p>{title}</p>
      <div className='flex justify-center gap-2 mt-4'>
        <button className='border px-4 py-2' onClick={onSelectNo}>No</button>
        <button className='border px-4 py-2' onClick={onYes}>Yes</button>
      </div>
    </div>
  </Modal>
  )
}

export default ConformationModel