import React, { useEffect, useMemo, useState } from 'react';
import { useApp } from "../hooks";
import {
  MdOutlineDelete,
  MdOutlineDownload,
  MdOutlinePlayArrow,
  MdOutlineStop
} from "react-icons/md";
import { FaFolderOpen } from 'react-icons/fa';
import {
  deleteFileService,
  downloadAudioFileService,
  getFilesListService
} from "../services/databases.service";
import {
  TableContainer,
  Table,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableSortLabel,
  Pagination,
  Modal,
  Typography,
  IconButton
} from '@mui/material';

const FilesList = ({ setTabs }: any) => {
  const { state, dispatch }: any = useApp();
  const [playingAudio, setPlayingAudio]: any = useState(null);
  const [playingFileName, setPlayingFileName]: any = useState(null);
  const [isDialogOpen, setIsDialogOpen]: any = useState(false);
  const [fileToDelete, setFileToDelete]: any = useState(null);
  const [pagination, setPagination]: any = useState({ pageIndex: 0, pageSize: 10 });
  const [sortField, setSortField]: any = useState('file_name');
  const [sortOrder, setSortOrder]: any = useState('asc');
  const [openModal, setOpenModal]: any = useState(false);
  const [modalTitle, setModalTitle]: any = useState('');
  const [modalContent, setModalContent]: any = useState(null);

  const setStatus = (message: any, type: any) => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: { show: true, title: 'Media Service', message: message, type: type }
    });
  };

  useEffect(() => {
    const fetchFiles = async () => {
      if (state.selectedOrganisation?._id) {
        const files: any = await getFilesListService(state.selectedOrganisation._id);
        dispatch({ type: 'SET_FILES', payload: files?.data?.data?.audio_files || [] });
      }
    };
    fetchFiles();
  }, [state.selectedOrganisation, dispatch]);

  const deleteFile = async (data: any) => {
    const { file_name } = data;
    setFileToDelete(file_name);
    setIsDialogOpen(true);
  };

  const handleDatabaseDeleteConfirm = async () => {
    if (fileToDelete) {
      const { success, message } = await deleteFileService(fileToDelete, state.selectedOrganisation._id);
      if (success === 1) {
        setStatus("File deleted successfully", "success");
        const files: any = await getFilesListService(state.selectedOrganisation._id);
        dispatch({ type: 'SET_FILES', payload: files?.data?.data?.audio_files || [] });
      } else {
        setStatus(message, "error");
      }
      setIsDialogOpen(false);
    }
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
    setFileToDelete(null);
  };

  const downloadFile = async (data: any) => {
    const { file_name } = data;
    const audio_file_data: any = await downloadAudioFileService(file_name, state.selectedOrganisation._id);
    if (audio_file_data?.data?.data?.audio_url) {
      const file_path = audio_file_data?.data?.data?.audio_url;
      const link = document.createElement('a');
      link.href = file_path;
      link.download = file_name;
      link.click();
    } else {
      setStatus("File not found", "error");
    }
  };

  const playFile = async (data: any) => {
    const { file_name }: any = data;
    if (playingAudio) {
      playingAudio.pause();
      playingAudio.currentTime = 0;
    }
    if (playingFileName === file_name) {
      setPlayingAudio(null);
      setPlayingFileName(null);
      return;
    }
    const audio_file_data: any = await downloadAudioFileService(file_name, state.selectedOrganisation._id);
    if (audio_file_data?.data?.data?.audio_url) {
      const file_path = audio_file_data?.data?.data?.audio_url;
      const audio = new Audio(file_path);
      audio.play();
      setPlayingAudio(audio);
      setPlayingFileName(file_name);
    } else {
      setStatus("File not found", "error");
    }
  };

  const handleSort = (field: any) => {
    const isAsc = sortField === field && sortOrder === 'asc';
    setSortField(field);
    setSortOrder(isAsc ? 'desc' : 'asc');
  };

  const columns = useMemo(() => [
    {
      accessorKey: 'file_name',
      header: 'File Name',
      cell: (info: any) => info.row.file_name,
    },
    {
      accessorKey: 'file_type',
      header: 'File Type',
      cell: (info: any) => info.row.file_type ?? 'N/A',
    },
    {
      accessorKey: 'file_size_mb',
      header: 'File Size (MB)',
      cell: (info: any) => info.row.file_size_mb ? `${info.row.file_size_mb.toFixed(2)} MB` : 'N/A',
    },
    {
      accessorKey: 'time_created',
      header: 'Time Created',
      cell: (info: any) => new Date(info.row.time_created).toLocaleString(),
    },
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: ({ row }: any) => (
        <div className="flex items-center justify-center space-x-2 py-2">
          {row.file_type === 'mp3' && (
            <IconButton onClick={() => playFile(row)} color="primary">
              {playingFileName === row.file_name ? <MdOutlineStop size={20} /> : <MdOutlinePlayArrow size={20} />}
            </IconButton>
          )}
          <IconButton onClick={() => downloadFile(row)} color="primary">
            <MdOutlineDownload size={20} />
          </IconButton>
          <IconButton onClick={() => deleteFile(row)} color="secondary">
            <MdOutlineDelete size={20} />
          </IconButton>
        </div>
      ),
    },
  ], [playingFileName]);

  const sortedData = useMemo(() => {
    if (!state.files) return [];
    let sorted = [...state.files];
    if (sortField) {
      sorted.sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    }
    return sorted;
  }, [state.files, sortField, sortOrder]);

  const paginatedData = useMemo(() => {
    const startIndex = pagination.pageIndex * pagination.pageSize;
    const endIndex = startIndex + pagination.pageSize;
    return sortedData.slice(startIndex, endIndex);
  }, [sortedData, pagination.pageIndex, pagination.pageSize]);

  const handlePaginationChange = (event: any, value: any) => {
    setPagination({ pageIndex: value - 1, pageSize: 10 });
  };

  return (
    <div className="flex flex-col" style={{ maxHeight: "75dvh", overflow: "auto" }}>
      <TableContainer component={Paper} elevation={3} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  key={col.accessorKey}
                  sx={{
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                  sortDirection={(sortField) === col.accessorKey ? sortOrder : false}
                >
                  <TableSortLabel
                    active={(sortField) === col.accessorKey}
                    direction={(sortField) === col.accessorKey ? sortOrder : "asc"}
                    onClick={() => handleSort(col.accessorKey)}
                  >
                    {col.header}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ height: "75vh" }}>
            {state.files.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} style={{ textAlign: "center" }}>
                  <FaFolderOpen size="6em" className="text-gray-400 mb-4" />
                  <p className="text-xl text-gray-500">No files available</p>
                </TableCell>
              </TableRow>
            ) : (
              paginatedData.map((row, idx) => (
                <TableRow key={idx}>
                  {columns.map((col) => (
                    <TableCell key={col.accessorKey} style={{ textAlign: "center" }}>
                      {col.cell ? col.cell({ row }) : row[col.accessorKey]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex justify-center mt-2">
        <Pagination
          count={Math.ceil(state.files.length / pagination.pageSize)}
          page={pagination.pageIndex + 1}
          onChange={handlePaginationChange}
        />
      </div>
      <Dialog
        open={isDialogOpen}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this file? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDatabaseDeleteConfirm} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: '70%',
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {modalTitle}
          </Typography>
          <Box id="modal-description" sx={{ mt: 2 }}>
            {modalContent}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default FilesList;
