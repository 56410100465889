import axiosApiInstance from "./request"

interface IPaymentPayload {
  model: 'gpt-4' | 'gpt-4-turbo' | 'gpt-3.5' | 'gpt-3.5-turbo';
  organisationId: string;
  successUrl: string;
  cancelUrl: string;
  type: 'input' | 'output';
}

const getCheckoutSession = async (payload : IPaymentPayload) => {
  try {
    const response = await axiosApiInstance.post('v1/payment/checkout', payload)
    return response.data
  } catch (error) {
    console.error(error)
    return null
  }
}

const getOrganisationSubscription = async (organisationId: string) => {
  try {
    const response = await axiosApiInstance.post('v2/subscription/organisation', {organisation_id: organisationId})
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

const getOrganisationBalance = async (organisationId: string) => {
  try {
    const response = await axiosApiInstance.post('v2/balance/organisation', {organisation_id: organisationId})
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

const getCustomerSession = async (organisationId: string, email: string) => {
  try {
    const response = await axiosApiInstance.post('v1/payment/get-customer-session', {organisation_id: organisationId, email: email})
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

const getBillingSession = async (organisationId: string, email: string) => {
  try {
    const response = await axiosApiInstance.post('v1/payment/get-billing-session', {organisation_id: organisationId, email: email})
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

export {
  getCheckoutSession,
  getOrganisationSubscription,
  getOrganisationBalance,
  getCustomerSession,
  getBillingSession
}