import { useState, useCallback } from "react";
import { useApp } from "../../hooks";
import { searchUsersService } from "../../services/auth.service";
import { assignCategoryService } from "../../services/category.service";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import Button from "../shared/Button";
import debounce from "lodash.debounce";

const ChatCategoryAssign = ({ category }: any) => {
  const { state, dispatch }: any = useApp();
  const { selectedOrganisation }: any = state;
  const [userOptions, setUserOptions]: any = useState([]);
  const [selectedUser, setSelectedUser]: any = useState(null);
  const [error, setError]: any = useState('');
  const [loading, setLoading]: any = useState(false);
  const [searchLoading, setSearchLoading]: any = useState(false);

  const fetchUsers = async (keyword: any) => {
    setSearchLoading(true);
    try {
      const response: any = await searchUsersService(keyword, selectedOrganisation._id);
      const options = response.data.map((user: any) => ({
        label: `${user.display_name} (${user.email})`,
        value: user.user_id
      }));
      setUserOptions(options);
    } catch (error) {
      console.log("error", error);
      // setError("Failed to fetch users");
    } finally {
      setSearchLoading(false);
    }
  };

  const debouncedFetchUsers: any = useCallback(debounce(fetchUsers, 300), [selectedOrganisation._id]);

  const handleSearch = (event: any) => {
    const keyword = event.target.value;
    if (keyword?.length < 3) return;
    debouncedFetchUsers(keyword);
  };

  const onSelectUser = (event: any, value: any) => {
    setError('');
    setSelectedUser(value);
  };

  const handleAssign = async () => {
    setLoading(true);
    if (selectedUser) {
      const payload: any = {
        user_id: selectedUser.value,
        category_name: category.category_name,
        organisation_id: selectedOrganisation._id
      };
      const { status, message }: any = await assignCategoryService(payload);
      if (status) {
        setLoading(false);
        setStatus('User has been successfully assigned to the category.', 'success');
      } else {
        setLoading(false);
        setError(message);
      }
    } else {
      setLoading(false);
      setError("Please select a user!");
    }
  };

  const setStatus = (message: any, type: any) => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: { show: true, title: 'Chat Topic Update', message, type }
    });
  };

  return (
    <div className="p-4">
      <div className="flex">
        <h1 className="mr-4 font-bold">Chat Topic: </h1><h1>{category.category_label}</h1>
      </div>
      <div className="mt-4">
        <Autocomplete
          options={userOptions}
          onInputChange={handleSearch}
          onChange={onSelectUser}
          loading={searchLoading}
          renderInput={(params: any) => (
            <TextField
              {...params}
              label="Search User"
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </div>
      <div className="flex justify-between items-center mt-4">
        <div>
          {error && (<span className="font-md text-red-600">{error}</span>)}
        </div>
        <div>
          <Button className="p-2" label="Assign" loading={loading} onClick={handleAssign} />
        </div>
      </div>
    </div>
  );
};

export default ChatCategoryAssign;
