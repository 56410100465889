import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PrimaryLoader from "../components/shared/PrimaryLoader";
import { sendVerificationEmail } from "../services/auth.service";
import { useAuth } from "../hooks";
import Modal from '@mui/material/Modal';

const VerifyEmailPage = () => {
    const navigate = useNavigate();
    const {auth}: any = useAuth();
    const [userEmail, setUserEmail] = useState(auth?.user?.email ?? '');
    const [showTooltip, setShowTooltip] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [timer, setTimer] = useState(0);
    const [emailSent, setEmailSent] = useState(false);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ text: '', type: '' });

    useEffect(() => {
        if(auth?.user?.email) {
            setUserEmail(auth.user.email);
        }
        if(auth?.user?.email_verified) {
            navigate("/organisation/:orgId/category/:categoryIdParam/thread/:threadIdParam");
        }
    }, [auth]);

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);

            return () => clearInterval(interval);
        } else {
            setDisableButton(false);
        }
    }, [timer]);

    const handleVerifyEmail = async () => {
        setLoading(true);
        try {
            await sendVerificationEmail(auth.user.email);
            setMessage({ text: 'Verification email sent!', type: 'success' });
            setDisableButton(true);
            setTimer(120); // 2 minutes
            setEmailSent(true);
        } catch (error) {
            setMessage({ text: 'Failed to send verification email.', type: 'error' });
        }
        setLoading(false);
    }

    const handleEmailVerified = () => {
        localStorage.setItem('refresh_user_session', 'true');
        setTimeout(() => window.location.reload(), 0);
    }

    return (
        <Modal open={true}>
            <div className="p-8 bg-white rounded-lg shadow-md">
                <h1 className="text-3xl font-bold text-gray-700 text-center mb-4">Verify your email</h1>
                <form>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="email">Email</label>
                        <input
                            className="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
                            id="email"
                            name="email"
                            type="text"
                            disabled
                            placeholder={userEmail}
                        />
                    </div>
                    {
                        message.text && (
                            <div className="mb-6">
                                <span className={`${message.type === 'error' ? 'text-red-600' : 'text-green-800'}`}>{message.text}</span>
                            </div>
                        )
                    }
                    {
                        loading && (
                            <div className="mb-6">
                                <PrimaryLoader />
                            </div>
                        )
                    }
                    <div className="flex w-full mt-8">
                        <button
                            className="w-full bg-gray-800 hover:bg-grey-900 text-white text-sm py-2 px-4 font-semibold rounded focus:outline-none focus:shadow-outline h-10"
                            type="button"
                            onClick={handleVerifyEmail}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            disabled={disableButton}
                        >
                            {disableButton ? `Resend in ${timer} seconds` : 'Send Verification Email'}
                        </button>
                    </div>
                    {
                        emailSent && (
                            <div className="flex w-full mt-4">
                                <button
                                    className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 font-semibold rounded focus:outline-none focus:shadow-outline h-10"
                                    type="button"
                                    onClick={handleEmailVerified}
                                >
                                    Click here once your email had been verified
                                </button>
                            </div>
                        )
                    }
                </form>
            </div>
        </Modal>
    );
}

export default VerifyEmailPage;
