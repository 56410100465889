import { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";
import { useApp, useAuth } from "../../hooks";
import { send_join_request } from "../../services/organisation.service";
import {
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Typography,
  ListItemButton,
  Collapse,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import PaymentsIcon from '@mui/icons-material/Payments';
import HomeIcon from '@mui/icons-material/Home';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import StorageIcon from '@mui/icons-material/Storage';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const MenuContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 360,
  backgroundColor: theme.palette.background.paper,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const AdminMenu = () => {
  const { state }: any = useApp();
  const { dispatch }: any = useApp();
  const { auth }: any = useAuth();
  const navigate = useNavigate();

  const { selectedOrganisation, userChatCategories } = state;

  const [openOrgMenu, setOpenOrgMenu] = useState(true);

  const handleOrgMenuClick = () => {
    setOpenOrgMenu(!openOrgMenu);
  };


  useEffect(() => {
    try {
      const join_request = localStorage.getItem('join_request');
      console.log("Join Request");
      console.log(join_request);
      if (!auth.user.email_verified) {
        navigate("/verify-email");
      }
      if (join_request?.length) {
        const response: any = send_join_request(join_request, auth.user.uid);
        response.then((res: any) => {
          console.log(res);
          if (res.status === 200) {
            dispatch({
              type: "SET_NOTIFICATION",
              payload: {
                show: true,
                title: "Join Request",
                message:
                  "Your join request to the organisation has been sent. You will be notified once the organisation accepts your request.",
                type: "info",
              },
            });
            localStorage.removeItem('join_request');
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);


  return (
    <MenuContainer>
      <Typography variant="h6" component="div" sx={{ textAlign: 'center', mb: 2 }}>
        Admin Menu
      </Typography>
      <Divider />

      <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      // subheader={
      //   <ListSubheader component="div" id="nested-list-subheader">
      //     Nested List Items
      //   </ListSubheader>
      // }
    >
      <ListItemButton onClick={() => {navigate(`/organisation/${selectedOrganisation._id}/category/1/thread/1`)}}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      <ListItemButton onClick={handleOrgMenuClick}>
        <ListItemIcon>
          <CorporateFareIcon />
        </ListItemIcon>
        <ListItemText primary="Organisation" />
        {openOrgMenu ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openOrgMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={() => {navigate('/organisation/settings')}}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}  onClick={() => {navigate('/organisation/users')}}>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Users"  />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => {navigate('/organisation/billing')}}>
            <ListItemIcon>
              <PaymentsIcon />
            </ListItemIcon>
            <ListItemText primary="Billing"/>
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton onClick={() => {navigate('/organisation/chat-category')}}>
        <ListItemIcon>
          <QuestionAnswerIcon />
        </ListItemIcon>
        <ListItemText primary="Chat Topic" />
      </ListItemButton>
      <ListItemButton onClick={() => {navigate('/organisation/databases')}}>
        <ListItemIcon>
          <StorageIcon />
        </ListItemIcon>
        <ListItemText primary="Drives" />
      </ListItemButton>
      <ListItemButton onClick={() => {navigate('/organisation/files')}}>
        <ListItemIcon>
          <FileCopyIcon />
        </ListItemIcon>
        <ListItemText primary="Media" />
      </ListItemButton>
    </List>
    </MenuContainer>
  );
}

export default AdminMenu;
