import axiosApiInstance from "./request"

interface IUserPayload {
  email: string,
  password: string,
  displayName?: string
}

const loginService = async (payload: IUserPayload) => {
  const response = { status: true, messsage: '', data: {} }
  try {
    const res = await axiosApiInstance.post('v1/login', payload)
    if (res.data.data.error) {
      response.status = false
      response.messsage = "Invalid credentials!"
    } else {
      response.data = res.data.data
    }
  } catch (error) {
    response.status = false
    response.messsage = "Invalid credentials!"
  }
  return response
}

const getUserRole = (organisation: any, userId: string) => {
  const { role } = organisation.members.find((m: any) => m.user_id === userId)
  return role
}

const sendVerificationEmail = async (email: any) => {
  const response = { status: true, messsage: '', data: {} }
  try {
    await axiosApiInstance.post('v2/auth/verify/email', {email: email})
  } catch (error: any) {
    response.status = false
    response.messsage = error.response.data.data.message
  }
  return response
}

const checkIfUserIsOwner = (organisation: any, userId: string) => {
  return getUserRole(organisation, userId) === 'owner'
}

const checkIfUserIsAdmin = (organisation: any, userId: string) => {
  return getUserRole(organisation, userId) === 'admin'
}


const registerService = async (payload: IUserPayload) => {
  const response = { status: true, messsage: '', data: {} }
  try {
    const res = await axiosApiInstance.post('v1/signup', payload)
    response.data = res.data.data
  } catch (error: any) {
    response.status = false
    response.messsage = error.response.data.data.message
  }
  return response
}

const getUserService = async () => {
  const response = { status: true, messsage: '', data: {} }
  try {
    const res = await axiosApiInstance.post('v1/user/info', {})
    response.data = res.data.data
  } catch (error: any) {
    response.status = false
    response.messsage = 'Failed!'
  }
  return response
}

const getUsersInfo = async (orgId: any, pageSize: any, pageIndex: any) => {
  const response = { status: true, messsage: '', data: {} }
  try {
    const res = await axiosApiInstance.post(`v1/user/info/users`, {org_id: orgId, page: pageIndex, size: pageSize})
    response.data = res.data
  } catch (error: any) {
    response.status = false
    response.messsage = 'Failed!'
  }
  return response
}

const getUsersInfoByIds = async (userIds: any, pageSize: any, pageIndex: any) => {
  const response = { status: true, messsage: '', data: {} }
  try {
    const res = await axiosApiInstance.post(`v1/user/info/users/list`, {user_ids: userIds, page: pageIndex, size: pageSize})
    response.data = res.data
  } catch (error: any) {
    response.status = false
    response.messsage = 'Failed!'
  }
  return response
}

const searchUsersService = async (keyword: any, organisation_id: any) => {
  const response = { status: true, messsage: '', data: {} }
  try {
    const res = await axiosApiInstance.post('v1/user/info/search', {keyword: keyword, org_id: organisation_id})
    response.data = res.data.data
  } catch (error: any) {
    response.status = false
    response.messsage = 'Failed!'
  }
  return response
}


const resetPasswordService = async (email: string) => {
  const response = { status: true, messsage: '', data: {} }
  try {
    await axiosApiInstance.post('v1/accounts/reset-password', {email: email})
  } catch (error: any) {
    response.status = false
    response.messsage = error.response.data.data.message
  }
  return response
}

const updateDisplayName = async (displayName: string) => {
  const response = { status: true, message: '', data: {} }
  try {
    const res = await axiosApiInstance.post('v1/accounts/change-display-name', {display_name: displayName})
    response.data = res.data.data
  } catch (error: any) {
    const message = error?.response?.data?.data?.message || 'Something went worng!'
    response.status = false
    response.message = message
  }
  return response
}

const changeEmail = async (email: string) => {
  const response = { status: true, message: '', data: {} }
  try {
    const res = await axiosApiInstance.post('v1/accounts/change-email', {email: email})
    response.data = res.data.data
  } catch (error: any) {
    const message = error?.response?.data?.message || 'Something went worng!'
    response.status = false
    response.message = message
  }
  return response
}

const chagePassword = async (password: any) => {
  const response = { status: true, message: '', data: {} }
  try {
    const res = await axiosApiInstance.post('v1/accounts/change_password', {password: password})
    response.data = res.data.data
  } catch (error: any) {
    const message = error?.response?.data?.message || 'Something went worng!'
    response.status = false
    response.message = message
  }
  return response
}

const assignCategory = async (paylaod: any) => {
  const response = { status: true, message: '', data: {} }
  try {

  } catch (error) {

  }
  return response
}

export {
  loginService,
  registerService,
  resetPasswordService,
  getUserService,
  updateDisplayName,
  changeEmail,
  chagePassword,
  assignCategory,
  checkIfUserIsAdmin,
  checkIfUserIsOwner,
  sendVerificationEmail,
  getUsersInfo,
  searchUsersService,
  getUsersInfoByIds
}