import './Loader.css';
import logo from '../../assets/logos/logo_white.png';
import { DNA } from 'react-loader-spinner';
import { useState, useEffect } from 'react';

const Loader = () => {
  const slogans = [
    "The ultimate solution for data integration and analysis",
    "Connect, Analyze, and Visualize your data",
    "Make sense of your data with DatalinkerAI",
    "Please wait while the server is booting up",
    "The smart way to handle your data",
    "Your data, your way",
    "The future of data management",
    "Use AI models to analyze and query your data",
    "All the best AI models in one place",
  ];

  const [sloganIndex, setSloganIndex] = useState(0);

  const [sloganText, setSloganText] = useState("");

  const [typing, setTyping] = useState(true);

  const updateSlogan = () => {
    const slogan = slogans[sloganIndex];

    if (sloganText.length < slogan.length) {
      setSloganText(sloganText + slogan.charAt(sloganText.length));
    } else {
      setTyping(false);
      setTimeout(() => {
        setSloganIndex((sloganIndex + 1) % slogans.length);
        setSloganText("");
        setTyping(true);
      }, 4000);
    }
  };

  useEffect(() => {
    const interval = setInterval(updateSlogan,30);
    return () => clearInterval(interval);
  }, [sloganIndex, sloganText, typing]);

  return (
    <div className="loader">
      <div className="spinner-container">
        <div className="pulse">
          <DNA
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </div>
        <div className="product-description">
          <img src={logo} width={350} alt="DatalinkerAI" />
          <p className="powered-by">Powered by The Virtulab</p>
        </div>
      </div>
      <div className="slogan-container flex justify-center items-center text-center">
  <p className="slogan">{sloganText}<span className={typing ? "cursor" : ""}></span></p>
</div>
    </div>
  )
}

export default Loader