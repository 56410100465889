import { Fragment } from "react";
import Spinner from "./Spinner";

interface IButton {
  label?: string;
  type?: 'button';
  className?: string;
  onClick: () => {};
  loading?: boolean
}

const Button = (props: IButton) => {

  const { label, type, className, onClick, loading } = props

  return (
    <Fragment>
      <button
        disabled={loading}
        type={type}
        className={`bg-gray-800 hover:bg-gray-700 border text-white text-sm py-2 px-4 font-semibold rounded focus:outline-none focus:shadow-outline h-10 ${className}`}
        onClick={onClick}
      >
        <div className="flex justify-center items-center">
          <div>
            { loading && (<Spinner/>) }
          </div>
          <div>
            {label}
          </div>
        </div>
      </button>
    </Fragment>
  )
}

export default Button