import React, { useEffect, useState } from "react";
import { FaComments, FaListAlt, FaPlusCircle } from "react-icons/fa";
import AddChatCategory from "../components/chatCategory/AddChatCategory";
import ChatCategoryList from "../components/chatCategory/ChatCategoryList";
import { useApp, useAuth } from "../hooks";
import { getChatCategoryService } from "../services/category.service";
import { getDatabasesService } from "../services/databases.service";
import { checkIfUserIsAdmin, checkIfUserIsOwner } from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { Box, Tab, Tabs, Typography } from "@mui/material";

const ChatCategoryPage = () => {
  const { state, dispatch }: any = useApp();
  const { auth }: any = useAuth();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (state.selectedOrganisation?._id) {
      const { role } = state.selectedOrganisation.members.find((m: any) => m.user_id === auth.user.user_id);
      if ((role === 'owner' || role === 'admin') && (state.selectedOrganisation?.subscription_status !== 'active')) {
        navigate('/organisation/billing');
        // show a notification
        dispatch({
          type: "SET_NOTIFICATION",
          payload: { show: true, title: 'Subscription', message: 'Your subscription is currently inactive. Please check your account settings.', type: 'error' }
        });
      }
      if (checkIfUserIsAdmin(state.selectedOrganisation, auth.user.user_id) || checkIfUserIsOwner(state.selectedOrganisation, auth.user.user_id)) {
        setInitialData();
      } else {
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: { show: true, title: 'Authorization', message: 'You are not authorized!', type: 'warning' }
        });
        navigate(`/organisation/${state.selectedOrganisation._id}/category/1/thread/1`);
      }
    }
  }, [state.selectedOrganisation]);

  useEffect(() => {
    setInitialDataForDatabases();
  }, [state.selectedOrganisation]);

  const setInitialDataForDatabases = async () => {
    if (state.selectedOrganisation?._id) {
      const databases = await getDatabasesService(state.selectedOrganisation._id, null, null, null, null);
      if (databases.response.length > 0) {
        dispatch({ type: 'SET_DATABASES', payload: databases.response });
      }
    }
  };

  const setInitialData = async () => {
    if (state.selectedOrganisation?._id) {
      const categories = await getChatCategoryService(state.selectedOrganisation._id);
      if (categories.length > 0) {
        dispatch({ type: 'SET_CHAT_CATEGORIES', payload: categories });
      }
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <div className="chat-category-page">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="chat category tabs">
          <Tab icon={<FaListAlt />} label="Chat Topics" />
          <Tab icon={<FaPlusCircle />} label="Add Chat Topic" />
        </Tabs>
      </Box>

      <Box sx={{ p: 3 }}>
        {tabIndex === 0 && (
          <ChatCategoryList />
        )}
        {tabIndex === 1 && (
          <AddChatCategory />
        )}
      </Box>
    </div>
  );
};

export default ChatCategoryPage;
