import { useState } from 'react';
import { FaCircle } from 'react-icons/fa';

import { Tabs, Tab, Box, Typography } from '@mui/material';
import GeneralSettings from '../components/accounts/GeneralSetting';
import SecuritySettings from '../components/accounts/SecuritySetting';

const SettingsPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Typography variant="h4" component="h1" sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <FaCircle size={20} />
        <span style={{ marginLeft: '16px' }}>Profile & Settings</span>
      </Typography>

      <Tabs value={selectedTab} onChange={handleTabChange} aria-label="settings tabs">
        <Tab label="General" />
        <Tab label="Security" />
      </Tabs>

      <Box sx={{ mt: 4 }}>
        {selectedTab === 0 && <GeneralSettings />}
        {selectedTab === 1 && <SecuritySettings />}
      </Box>
    </Box>
  );
};

export default SettingsPage;
