import { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { useApp } from '../../hooks';
import { chagePassword } from "../../services/auth.service";
import { TextField, Button, IconButton, Grid, Typography, Container } from '@mui/material';

const SecuritySettings = () => {
  const { dispatch }: any = useApp();
  const [password, setPassword] = useState('');
  const [editField, setEditField] = useState('');

  const handleEditClick = (field: any) => {
    setEditField(editField === field ? '' : field);
  };

  const savePassword = async () => {
    if (password) {
      const response = await chagePassword(password);
      if (response.status) {
        showMessage('success', 'Password changed successfully');
        setEditField('');
      } else {
        showMessage('error', response.message);
      }
    } else {
      showMessage('error', 'Please enter new password!');
    }
  };

  const showMessage = (type: any, message: any) => {
    dispatch({ type: 'SET_NOTIFICATION', payload: { show: true, title: 'User Update', message, type } });
  };

  return (
    <Container>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Change your password</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => handleEditClick('password')}>
            <MdEdit size={20} />
          </IconButton>
        </Grid>
        {editField === 'password' && (
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} sm={8}>
              <TextField
                label="Enter new password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} container spacing={2}>
              <Grid item xs={6}>
                <Button variant="contained" color="primary" onClick={savePassword} fullWidth>
                  Save
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant="outlined" onClick={() => setEditField('')} fullWidth>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default SecuritySettings;