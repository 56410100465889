import { useParams } from 'react-router-dom';

const AuthenticationStatusPage = () => {
  const { status } = useParams();

  const handleClick = () => {
    window.close()
  }

  return (
    <div className='flex justify-center items-center' style={{ height: "100dvh" }}>
      <div className='shadow-lg p-8'>
        <div className='mb-8'>
          <img src="/images/thevirtulab.png" width={150} alt="The Virtulab" />
          <h1 className='mt-4 text-xl font-bold'>Google Authentication</h1>
        </div>
        <div className='flex justify-center text-center'>
          {
            status === 'success' ? (
              <h1 className="text-green-800">Authenticated successfully</h1>
            ) : (
              <h1 className="text-red-600">Authentication Failed!</h1>
            )
          }
        </div>

        <div className='flex justify-end mt-8'>
          <button className="border py-2 px-4 rounded bg-gray-800 text-white" onClick={handleClick}> Okay </button>
        </div>
      </div>
    </div>
  )
}

export default AuthenticationStatusPage;