import Paper from "@mui/material/Paper";
import { Chat } from "../components/chat/Chat"
import FaqList from "../components/FaqList";
import { useApp } from "../hooks"
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const ChatContainer = styled(Paper)(({ theme }) => ({
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  ...theme.typography.body2,
  alignItems: 'center',
  display: 'flex',
}));

const HomePage = () => {
  const { state, dispatch } : any = useApp();

  return (

      // <ChatContainer square elevation={0}>
        <Chat />
      // </ChatContainer>
  )
}

export default HomePage