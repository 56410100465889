import { useState, useRef, useEffect } from 'react';
import { FaRegPaperPlane, FaTimes, FaMicrophone, FaPaperclip } from 'react-icons/fa';
import { speechToText } from '../../services/chat.service';
import Tooltip from '@mui/material/Tooltip';
import { useParams } from 'react-router-dom';
import { useApp } from '../../hooks';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import { Button, CircularProgress, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

interface CustomMentionInputProps {
  zapierActions: any[];
  value: any;
  onChange: (newValue: any) => any;
  onMentionSelect: (mention: any) => any;
  placeholder: string;
  sendMessage: (input: string, selectedMention: any, audioBlob: any, files: File[]) => any;
  loading: boolean;
  isCollaborativeThread?: boolean;
  isThreadOwner?: boolean;
  organisation_id: string;
}

const GradientButton = styled(Button)(({ theme }) => ({
  borderRadius: '50%',
  minWidth: 'auto',
  padding: '0.5rem',
  background: theme.palette.gradients.primary,
  color: '#FFFFFF',
  '&:hover': {
    background: theme.palette.gradients.primaryHover,
  },
  '&:active': {
    background: theme.palette.gradients.primaryActive,
  },
  transition: 'all 0.3s ease-in-out',
  boxShadow: `0 4px 14px 0 ${theme.palette.primary}`,
}));

const CustomMentionInput = ({
  zapierActions,
  value,
  onChange,
  onMentionSelect,
  placeholder,
  sendMessage,
  loading,
  isCollaborativeThread,
  isThreadOwner,
  organisation_id
}: CustomMentionInputProps) => {
  const [input, setInput] = useState('');
  const [selectedMentions, setSelectedMentions] = useState<any[]>([]);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [attachedFiles, setAttachedFiles]: any = useState<File[]>([]);
  const inputRef = useRef(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const recorderRef: any = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const { dispatch }: any = useApp();
  const { threadIdParam }: any = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    clearInput();
  }, [threadIdParam]);

  const handleInputChange = (e: any) => {
    setInput(e.target.value);
    if (e.target.value.includes('@') && selectedMentions.length === 0) {
      const mention = e.target.value.split('@')[1];
      filterSuggestions(mention);
    } else {
      setIsDropdownOpen(false);
    }
    onChange(e.target.value);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && isDropdownOpen && suggestions.length > 0) {
      e.preventDefault();
      selectMention(suggestions[0]);
    } else if (e.key === 'Enter' && !isDropdownOpen && !e.shiftKey && input.trim() !== '' && input.length > 0) {
      sendMessage(input, selectedMentions[0] ?? {}, audioBlob, attachedFiles);
      setTimeout(() => {
        clearInput();
      }, 100);
    }
  };

  const filterSuggestions = (value: any) => {
    const filteredSuggestions = zapierActions.filter((action: any) =>
      action.display.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
    setIsDropdownOpen(filteredSuggestions.length > 0);
  };

  const selectMention = (suggestion: any) => {
    const newMentions = [...selectedMentions, { ...suggestion }];
    setSelectedMentions(newMentions);
    onMentionSelect(newMentions);
    setIsDropdownOpen(false);
    if (inputRef.current) {
      (inputRef.current as any).focus();
    }
  };

  const clearInput = () => {
    setInput('');
    setSelectedMentions([]);
    onMentionSelect([]);
    setAudioBlob(null);
    setAttachedFiles([]);
    if (inputRef.current) {
      (inputRef.current as any).style.height = 'auto';
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const removeMention = (mentionId: any) => {
    setSelectedMentions(selectedMentions.filter((m: any) => m.id !== mentionId));
    onMentionSelect(selectedMentions);
  };

  const handleFileChange = (e: any) => {
    const newFiles = Array.from(e.target.files);
    const totalFiles = attachedFiles.length + newFiles.length;
    if (totalFiles > 5) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: 'File Attachment',
          message: 'You can attach a maximum of 5 files at a time.',
          type: 'error'
        }
      });
      return;
    }

    let hasInvalidFile = false;
    const validFileTypes = ['pdf', 'docx'];
    const newFilesData: File[] = [];

    newFiles.forEach((file: any) => {
      const fileType = file.name.split('.').slice(-1)[0];
      if (validFileTypes.includes(fileType)) {
        newFilesData.push(file);
      } else {
        hasInvalidFile = true;
      }
    });

    if (hasInvalidFile) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: 'Invalid File Type',
          message: 'Only PDF and DOCX files are allowed.',
          type: 'error'
        }
      });
      return;
    }

    setAttachedFiles([...attachedFiles, ...newFilesData]);
  };

  const removeFile = (fileIndex: number) => {
    setAttachedFiles(attachedFiles.filter((_: any, index: any) => index !== fileIndex));
  };

  const startRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error('getUserMedia not supported');
      return;
    }

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const audioContext = new AudioContext();
    const mediaStreamSource = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048;
    mediaStreamSource.connect(analyser);

    const dataArray: any = new Uint8Array(analyser.frequencyBinCount);

    let silenceDuration = 0;

    const checkSilence = () => {
      analyser.getByteTimeDomainData(dataArray);
      const maxAmplitude = Math.max(...dataArray);
      const silenceThreshold = 130;
      if (maxAmplitude < silenceThreshold) {
        silenceDuration += 500;
        if (silenceDuration >= 5000) {
          stopRecording();
        } else {
          setTimeout(checkSilence, 500);
        }
      } else {
        silenceDuration = 0;
        setTimeout(checkSilence, 500);
      }
    };

    recorderRef.current = new MediaRecorder(stream);
    recorderRef.current.ondataavailable = (e: any) => {
      setAudioBlob(e.data);
      recorderRef.current.onstop = () => {
        setIsRecording(false);
        sendAudio(e.data);
      };
    };

    setIsRecording(true);
    recorderRef.current.start();

    checkSilence();

    setTimeout(() => {
      stopRecording();
    }, 60000);
  };

  const stopRecording = async () => {
    if (recorderRef.current && recorderRef.current.state === 'recording') {
      try {
        recorderRef.current.stream.getTracks().forEach((track: any) => track.stop());
        await recorderRef.current.stop();
        console.log('Recording stopped');
      } catch (error) {
        console.error('Error stopping recording:', error);
      }
    } else {
      console.error('No recording to stop');
    }
  };

  const sendAudio = async (voiceData: any) => {
    if (!voiceData) {
      console.error('No audio recorded');
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(voiceData);
    reader.onloadend = async () => {
      const base64String = reader.result;
      const response: any = await speechToText(base64String, organisation_id);
      setInput(input + ' ' + response.data.data);
    };
  };

  useEffect(() => {
    return () => {
      if (recorderRef.current && recorderRef.current.state === 'recording') {
        recorderRef.current.stop();
      }
    };
  }, []);

  return (
    <Paper elevation={3} sx={{ p: 2, borderRadius: 2, boxShadow: (theme) => `0 4px 14px 0 ${theme.palette.gradients.primaryActive}` }}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={1} alignItems="flex-start">
          {!isMobile && (
            <Tooltip title="Attach">
              <IconButton onClick={() => fileInputRef.current?.click()} disabled={loading}>
                <GradientButton>
                  <FaPaperclip size={20} />
                </GradientButton>
              </IconButton>
            </Tooltip>
          )}
          <TextField
            label={placeholder}
            multiline
            maxRows={4}
            ref={inputRef}
            value={input}
            fullWidth
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            variant="outlined"
            InputProps={{
              style: { resize: 'none' },
            }}
            sx={{
              '& .MuiInputBase-root': {
                flexDirection: 'column-reverse',
              },
            }}
          />
          {!isMobile && (
            <Box display="flex" alignItems="center" gap={1}>
              <Tooltip title={isRecording ? "Stop Recording" : "Record"}>
                <IconButton onClick={isRecording ? stopRecording : startRecording} disabled={loading}>
                  <GradientButton>
                    {isRecording ? <FaTimes size={20} /> : <FaMicrophone size={20} />}
                  </GradientButton>
                </IconButton>
              </Tooltip>
              <Tooltip title="Send">
                <IconButton
                  onClick={() => {
                    if (input.trim() !== '' && input.length > 0) {
                      sendMessage(input, selectedMentions[0] ?? {}, audioBlob, attachedFiles);
                      clearInput();
                    }
                  }}
                  disabled={loading || (!isCollaborativeThread && !isThreadOwner)}
                >
                  <GradientButton>
                    {loading ? <CircularProgress size={20} color="inherit" /> : <FaRegPaperPlane size={20} />}
                  </GradientButton>
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
        {isMobile && (
          <Box display="flex" justifyContent="flex-end" gap={1} mt={1}>
            <Tooltip title="Attach">
              <IconButton onClick={() => fileInputRef.current?.click()} disabled={loading}>
                <GradientButton>
                  <FaPaperclip size={20} />
                </GradientButton>
              </IconButton>
            </Tooltip>
            <Tooltip title={isRecording ? "Stop Recording" : "Record"}>
              <IconButton onClick={isRecording ? stopRecording : startRecording} disabled={loading}>
                <GradientButton>
                  {isRecording ? <FaTimes size={20} /> : <FaMicrophone size={20} />}
                </GradientButton>
              </IconButton>
            </Tooltip>
            <Tooltip title="Send">
              <IconButton
                onClick={() => {
                  if (input.trim() !== '' && input.length > 0) {
                    sendMessage(input, selectedMentions[0] ?? {}, audioBlob, attachedFiles);
                    clearInput();
                  }
                }}
                disabled={loading || (!isCollaborativeThread && !isThreadOwner)}
              >
                <GradientButton>
                  {loading ? <CircularProgress size={20} color="inherit" /> : <FaRegPaperPlane size={20} />}
                </GradientButton>
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <input
        ref={fileInputRef}
        type="file"
        multiple
        accept=".pdf, .docx"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      {attachedFiles.length > 0 && (
        <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
          {attachedFiles.map((file: any, index: any) => (
            <Chip
              key={index}
              label={file.name}
              onDelete={() => removeFile(index)}
              color="secondary"
              sx={{ margin: '0.5rem' }}
            />
          ))}
        </Box>
      )}
      {isDropdownOpen && (
        <Box
          position="absolute"
          left={0}
          bottom={0}
          zIndex={50}
          bgcolor="background.paper"
          border="1px solid"
          borderColor="grey.300"
          borderRadius={1}
          boxShadow={3}
          p={2}
          width="100%"
        >
          {suggestions.map((suggestion: any) => (
            <Box
              key={suggestion.id}
              onClick={() => selectMention(suggestion)}
              sx={{
                cursor: 'pointer',
                padding: '0.5rem',
                '&:hover': { backgroundColor: 'grey.100' },
              }}
            >
              {suggestion.display}
            </Box>
          ))}
        </Box>
      )}
    </Paper>
  );
};

export default CustomMentionInput;
