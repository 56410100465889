import { useState } from "react";
import DatabaseContentForm from "./DatabaseContentForm";

const AddDatabase = ({setTabs}: any) => {

  const [tab, setTab] = useState({
    component: <DatabaseContentForm setTabs={setTabs}/>
  })

  return (
    <div className="w-full">

      <div>
        {tab.component}
      </div>
     
    </div>
  )
}

export default AddDatabase