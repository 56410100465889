import { useApp, useAuth } from "../../hooks";
import { motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/logos/logo.png';
import { send_join_request } from "../../services/organisation.service";
import { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { Divider, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { deleteThreadService } from "../../services/chat.service";

const ChatCategoryMenu = () => {
  const { state, dispatch }: any = useApp();
  const { userChatCategories, selectedOrganisation, generalThreads, selectedChatCategory, selectedThread } = state;
  const navigate = useNavigate();
  const { auth }: any = useAuth();
  const [isTopicsCollapsed, setIsTopicsCollapsed] = useState(true);
  const [isThreadsCollapsed, setIsThreadsCollapsed] = useState(true);
  const { threadIdParam, categoryIdParam, orgId }: any  = useParams();

  const [chatTopicSelectedIndex, setChatTopicSelectedIndex] = useState(1);
  const [chatThreadSelectedIndex, setChatThreadSelectedIndex] = useState(1);

  const handleCollapseTopicsClick = () => {
    setIsTopicsCollapsed(!isTopicsCollapsed);
  };

  const handleCollapseThreadsClick = () => {
    setIsThreadsCollapsed(!isThreadsCollapsed);
  }

  useEffect(() => {
    try {
      const join_request = localStorage.getItem('join_request');

      if (join_request?.length) {
        const response: any = send_join_request(join_request, auth.user.uid);
        response.then((res: any) => {
          if (res.status === 200) {
            dispatch({
              type: "SET_NOTIFICATION",
              payload: {
                show: true,
                title: "Join Request",
                message:
                  "Your join request to the organisation has been sent. You will be notified once the organisation accepts your request.",
                type: "info",
              },
            });
            localStorage.removeItem('join_request');
          }
        });
      }
      
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (!userChatCategories.length) {
      routeToNewGeneralThread()
    }
  }, [userChatCategories]);

  useEffect(() => {
    if(categoryIdParam !== 'GENERAL' && selectedChatCategory) {
      const index = userChatCategories.findIndex((ucc: any) => ucc.category_id === categoryIdParam);
      setChatTopicSelectedIndex(index);
      setChatThreadSelectedIndex(-1);
    }
  }, [categoryIdParam]);

  useEffect(() => {
    if(selectedThread && categoryIdParam === 'GENERAL') {
      const index = generalThreads.findIndex((ucc: any) => ucc.thread_id === threadIdParam);
      setChatThreadSelectedIndex(index);
      setChatTopicSelectedIndex(-1);
    }
  }, [threadIdParam]);

  const handleCategoryListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    ucc: any
  ) => {
    // setChatTopicSelectedIndex(index);
    // setChatThreadSelectedIndex(-1);
    onSelectCategory(ucc);
  };

  const handleThreadListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    ucc: any
  ) => {
    // setChatThreadSelectedIndex(index);
    // setChatTopicSelectedIndex(-1);
    onSelectThread(ucc);
  };

  const deleteThread = async (event: any, index: number, ucc: any) => {
    event.stopPropagation();
    console.log('Delete Thread', ucc);
    const response = await deleteThreadService(ucc.thread_id);
    console.log(response);
    const newThreads = generalThreads.filter((thread: any) => thread.thread_id !== ucc.thread_id);

    dispatch({
      type: 'SET_GENERAL_THREADS',
      payload: newThreads
    });
  }

  const onSelectCategory = async (cc: any) => {
    try {
      dispatch({
        type: 'SET_USER_ACTIVE_CHAT_CATEGORY',
        payload: cc
      });
      navigate(`/organisation/${selectedOrganisation._id}/category/${cc?.category_id}/thread/${uuid()}`);
    } catch (error) {
      console.error(error);
    }
  }

  const onSelectThread = async (thread: any) => {
    console.log(thread);
    try {
      dispatch({
        type: 'SELECT_THREAD',
        payload: thread
      });
      navigate(`/organisation/${selectedOrganisation._id}/category/${thread?.category_id}/thread/${thread?.thread_id}`);
      //  close the drawer

    } catch (error) {
      console.error(error);
    }
  }

  const routeToCreateTopic = () => {
    navigate(`/organisation/chat-category`);
  }

  const routeToNewGeneralThread = () => {
    try {
      dispatch({
        type: 'SET_USER_ACTIVE_CHAT_CATEGORY',
        payload: { category_id: 'GENERAL', category_name: 'General' }
      });
      const new_thread_id = uuid();
      const thread = { thread_id: new_thread_id, thread_name: 'New Thread', category_id: 'GENERAL'}
      generalThreads.unshift(thread);
      dispatch({
          type: 'SET_GENERAL_THREADS',
          payload: generalThreads
      });
      const index = generalThreads.findIndex((ucc: any) => ucc.thread_id === new_thread_id);
      dispatch({
        type: 'SELECT_THREAD',
        payload: thread
      });
      setChatThreadSelectedIndex(index);
      setChatTopicSelectedIndex(-1);
      navigate(`/organisation/${selectedOrganisation._id}/category/GENERAL/thread/${new_thread_id}`);
    } catch (error) {
      console.error(error);
    }
  }

  const [maxHeight, setMaxHeight] = useState('0px');

  const updateMaxHeight = () => {
    // Example: Subtract 100px for other fixed elements like headers or footers
    const availableHeight = window.innerHeight - 300;
    // Assuming there are 2 collapsible items, divide by 2
    setMaxHeight(`${availableHeight / 2}px`);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMaxHeight);
    // Initial calculation
    updateMaxHeight();

    return () => window.removeEventListener('resize', updateMaxHeight);
  }, []);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-hidden">
        { userChatCategories?.length  && (
          <List
            sx={{ width: '100%', maxWidth: 490, bgcolor: 'background.paper', overflow: 'auto' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton onClick={handleCollapseTopicsClick}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Chat Topics" />
              <IconButton onClick={routeToCreateTopic} aria-label="add">
                <AddIcon />
              </IconButton>
              {isTopicsCollapsed ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isTopicsCollapsed} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ maxHeight: maxHeight, overflow: 'auto' }}>
                {userChatCategories.map((ucc: any, index: number) => {
                  if (ucc.category_id === 'GENERAL') {
                    return null;
                  }
                  return (
                    <ListItemButton
                      selected={chatTopicSelectedIndex === index}
                      onClick={(event: any) => handleCategoryListItemClick(event, index, ucc)}
                    >
                      <ListItemText primary={ucc.category_name} />
                    </ListItemButton>
                  );
                })}
              </List>
            </Collapse>
          </List>
        )}

        <Divider />

        <List
          sx={{ width: '100%', maxWidth: 490, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="My Chats" />
            <IconButton onClick={routeToNewGeneralThread} aria-label="add">
              <AddIcon />
            </IconButton>
            {isThreadsCollapsed ? <ExpandLess onClick={handleCollapseThreadsClick}/> : <ExpandMore onClick={handleCollapseThreadsClick}/>}
          </ListItemButton>
          <Collapse in={isThreadsCollapsed} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ maxHeight: maxHeight, overflow: 'auto' }}>
              {generalThreads.map((ucc: any, index: number) => {
                return (
                  <ListItemButton
                    selected={chatThreadSelectedIndex === index}
                    onClick={(event: any) => handleThreadListItemClick(event, index, ucc)}
                  >
                    <ListItemText primary={ucc.thread_name} />
                    <IconButton edge="end" aria-label="delete"onClick={(event: any) => deleteThread(event, index, ucc)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemButton>
                );
              })}
            </List>
          </Collapse>
        </List>
      </div>

      <motion.div
        className="flex items-center justify-center p-2 text-gray-800 bg-gray-200 rounded-lg"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <img src={logo} alt="DatalinkerAI" width={100} />
        <div className="absolute bottom-1 right-0 bg-red-500 text-white text-xs py-2 px-3 rounded-bl-md">
          BETA
        </div>
      </motion.div>
    </div>
  );
}

export default ChatCategoryMenu;