import React, { useMemo, useState } from 'react';
import { useApp } from "../../hooks";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { CgUserList } from "react-icons/cg";
import { HiUserAdd } from "react-icons/hi";
import ChatCategoryAssign from "./ChatCategoryAssign";
import ChatCategoryUsers from "./ChatCategoryUsers";
import { deleteChatCategoryService, getAllChatCategoryService } from "../../services/category.service";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import ChatContentForm from "./ChatContentForm";
import {
  TableContainer, Table, Paper, TableRow, TableHead, TableCell, TableBody,
  Button, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  TableSortLabel,
  Pagination,
  Chip,
  Modal,
  Typography,
  Tooltip
} from '@mui/material';

const ChatCategoryList = () => {
  const { state, dispatch }: any = useApp();
  const { userChatCategories }: any = state;

  const [isDialogOpen, setIsDialogOpen]: any = useState(false);
  const [categoryToDelete, setCategoryToDelete]: any = useState(null);
  const [pagination, setPagination]: any = useState({ pageIndex: 1, pageSize: 10 });
  const [sortField, setSortField]: any = useState('category_name');
  const [sortOrder, setSortOrder]: any = useState('asc');

  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);


  const deleteChatCategory = (category: any) => {
    setCategoryToDelete(category);
    setIsDialogOpen(true);
  };

  const handleConfirm = async () => {
    if (!categoryToDelete) return;
    const isDeleted = await deleteChatCategoryService(state.selectedOrganisation._id, categoryToDelete.category_id);
    if (isDeleted) {
      setStatus('Deleted Successfully', 'success');
      const categories = await getAllChatCategoryService(state.selectedOrganisation._id);
      dispatch({ type: 'SET_USER_CHAT_CATEGORIES', payload: categories || [] });
      if (categories.length > 0) {
        dispatch({ type: 'SET_USER_ACTIVE_CHAT_CATEGORY', payload: categories[0] || {} });
      }
    } else {
      setStatus('Deletion failed!', 'error');
    }
    setIsDialogOpen(false);
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
  };

  const setStatus = (message: any, type: any) => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: { show: true, title: 'Chat Topic delete', message, type }
    });
  };

  const handleSort = (field: any) => {
    const isAsc = sortField === field && sortOrder === 'asc';
    setSortField(field);
    setSortOrder(isAsc ? 'desc' : 'asc');
  };

  const sortedData = useMemo(() => {
    if (!userChatCategories) return [];
    let sorted = [...userChatCategories];
    if (sortField) {
      sorted.sort((a: any, b: any) => {
        if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    }
    return sorted;
  }, [userChatCategories, sortField, sortOrder]);

  const paginatedData = useMemo(() => {
    const startIndex = (pagination.pageIndex - 1) * pagination.pageSize;
    const endIndex = startIndex + pagination.pageSize;
    return sortedData.slice(startIndex, endIndex);
  }, [sortedData, pagination.pageIndex, pagination.pageSize]);

  const handlePaginationChange = (event: any, value: any) => {
    setPagination({ ...pagination, pageIndex: value });
  };

  const columns: any = [
    { accessorKey: 'category_name', header: 'Chat Topic Name' },
    { accessorKey: 'versions', header: 'Version', cell: (row: any) => row?.versions?.length ?? 'N/A' },
    {
      accessorKey: 'drives',
      header: 'Drives',
      cell: (row: any) => {
        let databases = state.databases || [];
        console.log("databases", databases);
        const databasesOfCategory = row.databaseIds || [];
        databases = databases.filter((db: any) => databasesOfCategory.includes(db.database_id));
        return (
          <div>
            {databases.map((db: any) => (
              <Tooltip title={db.database_name} key={db.database_id}>
              <Chip
                label={db.database_name}
                key={db.database_id}
                style={{
                  maxWidth: '150px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  margin: '2px'
                }}
              />
              </Tooltip>
            ))}
          </div>
        );
      }
    },
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: (row: any) => (
        <div className="flex items-center justify-center space-x-2 py-2">
          <Button onClick={() => openModalWithContent('Update Chat Topic', <ChatContentForm
      initialCategoryId={row.category_id}
      initialCategoryInstruction={row.category_instruction}
      initialDatabaseIds={row.databaseIds}
      initialCategoryName={row.category_name}
    />)} color="primary">
            <MdOutlineEdit size={20} />
          </Button>
          <Button onClick={() => deleteChatCategory(row)} color="secondary">
            <MdOutlineDelete size={20} />
          </Button>
          <Button onClick={() => openModalWithContent('Chat Topic Users', <ChatCategoryUsers category={row} />)}>
            <CgUserList size={20} />
          </Button>
          <Button onClick={() => openModalWithContent('Assign Chat Topic', <ChatCategoryAssign category={row} />)}>
            <HiUserAdd size={20} />
          </Button>
        </div>
      )
    }
  ];

  const openModalWithContent = (title: string, content: any) => {
    setModalTitle(title);
    setModalContent(content);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="flex flex-col" style={{ maxHeight: "75dvh", overflow: "auto" }}>
      <TableContainer component={Paper} elevation={3} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col: any) => (
                <TableCell
                  key={col.accessorKey}
                  sx={{
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    height: "50px"
                  }}
                  sortDirection={sortField === col.accessorKey ? sortOrder : false}
                >
                  <TableSortLabel
                    active={sortField === col.accessorKey}
                    direction={sortField === col.accessorKey ? sortOrder : "asc"}
                    onClick={() => handleSort(col.accessorKey)}
                  >
                    {col.header}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ height: "75vh" }}>
            {paginatedData.map((row: any, idx: any) => (
              <TableRow key={idx}>
                {columns.map((col: any) => (
                  <TableCell key={col.accessorKey} sx={{ alignItems: 'center', textAlign: 'center' }}>
                    {col.cell ? col.cell(row) : row[col.accessorKey]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex justify-center mt-2">
        <Pagination
          count={Math.ceil(userChatCategories?.length / pagination.pageSize)}
          page={pagination.pageIndex}
          onChange={handlePaginationChange}
        />
      </div>
      <Dialog
        open={isDialogOpen}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this category? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: '70%',
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {modalTitle}
          </Typography>
          <Box id="modal-description" sx={{ mt: 2 }}>
            {modalContent}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ChatCategoryList;
