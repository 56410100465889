import { useEffect, useState } from "react";
import { FaDatabase, FaPlusCircle } from "react-icons/fa";
import DatabasesList from "../components/databases/DatabasesList";
import { useApp, useAuth } from "../hooks";
import AddDatabase from "../components/databases/AddDatabase";
import { getDatabasesService } from "../services/databases.service";
import { checkIfUserIsAdmin, checkIfUserIsOwner } from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab, Box, Typography } from '@mui/material';

const DatabasesPage = () => {
  const { state, dispatch }: any = useApp();
  const [selectedTab, setSelectedTab] = useState(0);
  const { auth }: any = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.selectedOrganisation?._id) {
      console.log("state.selectedOrganisation" + state.selectedOrganisation?._id);
      const { role } = state.selectedOrganisation.members.find((m: any) => m.user_id === auth.user.user_id);
      if ((role === 'owner' || role === 'admin') && (state.selectedOrganisation?.subscription_status !== 'active')) {
        navigate('/organisation/billing');
        // show a notification
        dispatch({ type: "SET_NOTIFICATION", payload: { show: true, title: 'Subscription', message: 'Your subscription is currently inactive. Please check your account settings.', type: 'error' } });
      }
      if (checkIfUserIsAdmin(state.selectedOrganisation, auth.user.user_id) || checkIfUserIsOwner(state.selectedOrganisation, auth.user.user_id)) {
        setInitialData();
      } else {
        dispatch({ type: 'SET_NOTIFICATION', payload: { show: true, title: 'Authorization', message: 'You are not authorized!', type: 'warning' } });
        navigate(`/organisation/${state.selectedOrganisation._id}/category/1/thread/1`);
      }
    }
  }, [state.selectedOrganisation]);

  useEffect(() => {
    console.log("state.databases");
    console.log(state.databases);
    if (state.databases?.length > 0) {
      setSelectedTab(0); // Default to "Drives" tab
    }
  }, [state.databases]);

  const setInitialData = async () => {
    if (state.selectedOrganisation?._id) {
      const databases = await getDatabasesService(state.selectedOrganisation._id, null, null, null, null);
      if (databases.response.length > 0) {
        dispatch({ type: 'SET_DATABASES', payload: databases.response });
      } else {
        setSelectedTab(1); // Default to "Create Drive" tab if no databases are found
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="databases-page">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleChange} aria-label="databases tabs">
          <Tab icon={<FaDatabase size={20} />} label="Drives" />
          <Tab icon={<FaPlusCircle size={20} />} label="Create Drive" />
        </Tabs>
      </Box>
      <div className="chat-category-container">
        {selectedTab === 0 && <DatabasesList setTabs={setSelectedTab} />}
        {selectedTab === 1 && <AddDatabase setTabs={setSelectedTab} />}
      </div>
    </div>
  );
};

export default DatabasesPage;
