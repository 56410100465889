import React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AssistantIcon from '@mui/icons-material/Assistant';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { DarkModeToggle } from '../../App';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import { AccountMenu } from './AccountMenu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';



// Props are now accepted from the parent component
export default function ChatConfiguration({
  selectedModel,
  setSelectedModel,
  isDrawerOpen,
  setIsDrawerOpen,
  isCollaborativeThread,
  createCollaborativeThread,
  k_value,
  setKValue,
  videoToggleEnabled,
  collaborationToggleEnabled,
  auth, 
  profilePhoto,
  handleProfile,
  handleLogout
}: any) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [checked, setChecked] = React.useState<string[]>([]);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsOpen(open);
  };

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const valuetext = (value: number) => {
    setKValue(Number(value));
    return `${value}°C`;
  }

  const list = (
    <Box
      sx={{ width: isMobile ? '70vw' : '30vw' }}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <List
        sx={{ width: '100%', maxWidth: isMobile ? '70vw' : '30vw', bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            { isMobile ? <SettingsIcon onClick={toggleDrawer(false)}/> : 'Chat Configuration' }
          </ListSubheader>
        }
      >
        {/* Chat Model Selection */}
        <ListItem>
          <ListItemIcon>
            <AssistantIcon />
          </ListItemIcon>
          <ListItemText primary="Chat Model" />
          <FormControl sx={{ m: 1, minWidth: '10vw' }}>
            <InputLabel id="model-select-label">Model</InputLabel>
            <Select
              labelId="model-select-label"
              id="model-select"
              value={selectedModel}
              onChange={(e) => setSelectedModel(e.target.value as string)}
              autoWidth
              label="Model"
            >
              <MenuItem value="gpt-4">GPT-4</MenuItem>
              <MenuItem value="gpt-4o">GPT-4o</MenuItem>
              <MenuItem value="gpt-4-turbo">GPT-4 Turbo</MenuItem>
              <MenuItem value="gpt-3.5-turbo">GPT-3.5 Turbo</MenuItem>
              <MenuItem value="gemini-pro">GEMINI PRO</MenuItem>
              <MenuItem value="claude-3-opus">CLAUDE 3 OPUS</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        <Divider />
        {/* Collaboration Switch */}
        <ListItem>
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText primary="Collaboration" />
          <Switch
            disabled={!collaborationToggleEnabled}
            edge="end"
            checked={isCollaborativeThread}
            onChange={() => createCollaborativeThread(!isCollaborativeThread)}
            inputProps={{
              'aria-labelledby': 'switch-list-label-collaboration',
            }}
          />
        </ListItem>
        <Divider />
        {/* Precision Control Slider */}
        <ListItem>
          <ListItemIcon>
            <PrecisionManufacturingIcon />
          </ListItemIcon>
          <ListItemText primary="Precision Control" />
          
        </ListItem>

        <ListItem>
        <div className="flex flex-row items-center w-96">
                        <div className="mr-4">Narrow</div>
                        <Slider
                          aria-label="Temperature"
                          defaultValue={10}
                          getAriaValueText={valuetext}
                          valueLabelDisplay="auto"
                          shiftStep={2}
                          step={2}
                          marks
                          min={2}
                          max={20}
                          className="flex flex-row items-center mr-2 w-64"
                          color="primary"
                        />
                        <div className="ml-2">Wider</div>
                      </div>
        </ListItem>

        <Divider />
        {/* Show Video Switch */}
        <ListItem>
          <ListItemIcon>
            <VideoSettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Show Video" />
          <Switch
            disabled={!videoToggleEnabled}
            edge="end"
            checked={isDrawerOpen}
            onChange={() => setIsDrawerOpen(!isDrawerOpen)}
            inputProps={{
              'aria-labelledby': 'switch-list-label-video',
            }}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
              <SettingsBrightnessIcon />
            </ListItemIcon>
            <ListItemText primary="Dark Mode" />
           <DarkModeToggle />
        </ListItem>
       
        { isMobile && (
          <div>
            <Divider />
          <ListItem>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" />
          <AccountMenu auth={auth} profilePhoto={profilePhoto} handleProfile={handleProfile} handleLogout={handleLogout} />
        </ListItem>
          </div>
           
        )}
        
      </List>
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>{ isMobile ? <SettingsIcon /> : 'Chat Configuration' }</Button>
      <SwipeableDrawer
        anchor='right'
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list}
      </SwipeableDrawer>
    </div>
  );
}