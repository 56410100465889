import { useState } from "react";
import { useApp } from "../../hooks";
import { updateUserRoleService } from "../../services/organisation.service";
import {
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";

const EditUserForm = ({ userData }: any) => {
  const { state, dispatch }: any = useApp();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const roles = [
    {
      label: "Admin",
      value: "admin",
    },
    {
      label: "Staff",
      value: "staff",
    },
  ];

  const [selectedRole, setSelectedRole] = useState({
    label: userData.role.charAt(0).toUpperCase() + userData.role.slice(1),
    value: userData.role,
  });

  const onRoleChange = (event: any) => {
    const role: any = roles.find((r) => r.value === event.target.value);
    setSelectedRole(role);
  };

  const updateUser = async () => {
    setLoading(true);
    if (selectedRole) {
      const { status, message } = await updateUserRoleService({
        user_id: userData.user_id,
        org_id: state.selectedOrganisation._id,
        role: selectedRole.value,
      });
      console.log(status, message);
      if (status) {
        setLoading(false);
        dispatch({
          type: "SHOW_MODAL",
          payload: { show: false, title: "", component: null, className: "" },
        });
        dispatch({
          type: "SET_NOTIFICATION",
          payload: {
            show: true,
            title: "Update User",
            message: "User role updated.",
            type: "success",
          },
        });
      } else {
        setLoading(false);
        setError(message);
      }
    } else {
      setLoading(false);
      setError("Please select a role for the user!");
    }
  };

  return (
    <Box>
      <Typography variant="h6">{userData.userInfo.display_name}</Typography>
      <Box mt={2}>
        <FormControl fullWidth>
          <InputLabel>Select Role</InputLabel>
          <Select
            value={selectedRole.value}
            onChange={onRoleChange}
            label="Select Role"
          >
            {roles.map((role) => (
              <MenuItem key={role.value} value={role.value}>
                {role.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box mt={4} display="flex" justifyContent="space-between">
        <Button
          variant="contained"
          color="primary"
          onClick={updateUser}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Update"}
        </Button>
      </Box>
      {error && (
        <Box mt={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default EditUserForm;
