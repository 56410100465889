import Markdown from "../shared/Markdown";
import { FaCheck, FaCopy, FaPlay, FaStop, FaRobot, FaUser } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { useState, useRef, useEffect, useCallback } from "react";
import './ChatLine.css';
import { textToSpeech } from "../../services/chat.service";
import Spinner from "../shared/Spinner";
import { useApp } from "../../hooks";
import { styled, useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

export type Message = {
  who: 'bot' | 'user' | undefined;
  message?: string;
  spents: any;
  messageId: string;
  threadId: string;
  username: string | undefined;
  userId: string | undefined;
  createdAt: string;
};

const generateRandomWidths = () => {
  const widths = [];
  let total = 0;

  while (total < 100) {
    const remaining = 100 - total;
    const width = Math.min(remaining, Math.random() * 30 + 10);
    widths.push(width);
    total += width;
  }

  return widths;
};

export const LoadingChatLine = () => {
  const theme = useTheme();
  const rows = Array.from({ length: 3 }, generateRandomWidths);

  return (
    <Box
      sx={{
        display: 'flex',
        minWidth: '100%',
        px: 4,
        py: 3,
        animation: 'pulse 1.5s ease-in-out infinite',
        '@keyframes pulse': {
          '0%': { opacity: 1 },
          '50%': { opacity: 0.5 },
          '100%': { opacity: 1 },
        },
      }}
    >
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h6" component="p" sx={{ fontWeight: 'bold' }}>
          DatalinkerAI
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Generating a response, please wait...
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {rows.map((row, rowIndex) => (
            <Box key={rowIndex} sx={{ display: 'flex', gap: 1 }}>
              {row.map((width, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    height: 20,
                    borderRadius: 1,
                    background: theme.palette.gradients.primaryHover,
                    width: `${width}%`,
                  }}
                />
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const ChatLineContainer = styled(Paper)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: theme.spacing(2)
}));

export function ChatLine({ who = 'bot', message, spents, threadId, messageId, userId, username, createdAt }: Message) {
  const [isCopied, setIsCopied] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { state, dispatch }: any = useApp();

  const copyToClipboard = async () => {
    if (message) {
      try {
        await navigator.clipboard.writeText(message);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
      } catch (err) {
        console.log('Something went wrong', err);
      }
    }
  };

const playAudio = async () => {
        if (!message) {
            return;
        }
        setIsLoading(true);
        const audioElement: any = audioRef.current;
        if (!audioElement) {
            console.error('No audio element found');
            return;
        }

        const response: any = await textToSpeech(message, messageId, state.selectedOrganisation._id);
        if (response.data && response.data.data.audio_url) {
            audioElement.src = response.data.data.audio_url;
            audioElement.play();
            setIsLoading(false);
            setIsPlaying(true);

            audioElement.onended = () => {
                setIsPlaying(false);
            };
        } else {
            setIsLoading(false);
            console.log('Failed to get audio URL from response');
        }
    };


  const stopAudio = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  }, [audioRef, setIsPlaying]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const chatLineId = `${who === 'bot' ? `bot-${messageId}` : `user-${messageId}`}`;
      const chatLineElement = document.getElementById(chatLineId);

      if (chatLineElement) {
        const chatLineTop = chatLineElement.offsetTop;
        const chatLineBottom = chatLineTop + chatLineElement.offsetHeight;

        if (scrollY > chatLineBottom || scrollY < chatLineTop) {
          stopAudio();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [who, messageId, stopAudio]);

  return (
    <AnimatePresence>
      {message && (
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.3 }}
          className={`clear-both flex justify-center py-2`}
          style={{ maxWidth: '100%', overflow: 'auto' }}
        >
          <ChatLineContainer square={false} elevation={2}>
          {/* <div
            className={`float-right mb-5 rounded-lg px-4 py-5 shadow-lg ring-1 ${
              who === 'bot' ? 'bg-gray-100 text-gray-900' : 'bg-gray-200 text-gray-900'
            } sm:px-6 message-list-box`}
            id={`${who === 'bot' ? `bot-${messageId}` : `user-${messageId}`}`}
            style={{
              boxShadow: who === 'bot' ? '0 4px 6px -1px rgba(13, 113, 130, 0.1), 0 2px 4px -1px rgba(13, 113, 130, 0.06)' : '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
              transition: 'border-color 0.5s ease',
              width: '100%',
            }}
          > */}
            <div className="flex space-x-3 flex-row-reverse">
              <div className="flex-1 gap-4">
                <p className="font-large text-xxl font-sans flex items-center ai-title">
                  {who === 'bot' ? (
                     <Typography variant="subtitle2"><FaRobot className="mr-2 ai-icon" /></Typography>
                  ) : (
                    <Typography variant="subtitle2"><FaUser className="mr-2 ai-icon" /></Typography>
                  )}
                  {who === 'bot' ? <Typography variant="subtitle2">DatalinkerAI</Typography> : <Typography variant="subtitle2">{username}</Typography>}
                </p>
                <div className={`text ${who !== 'bot' ? '' : ''}`} style={{ overflow: 'auto', position: 'relative' }}>
                  <Markdown markdown={message} />
                  {who === 'bot' && (
                    <div style={{ position: 'absolute', top: 0, right: '10px' }}>
                      {isCopied ? (
                        <motion.div
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          exit={{ scale: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <FaCheck style={{ color: 'green' }} />
                        </motion.div>
                      ) : (
                        <button onClick={copyToClipboard} style={{ padding: 2, borderRadius: '50%' }}>
                          <FaCopy className="hover:text-blue-400" />
                        </button>
                      )}
                      <button onClick={isPlaying ? stopAudio : playAudio} style={{ padding: 2, borderRadius: '50%' }}>
                        {isLoading ? <Spinner/> : isPlaying ? <FaStop className="hover:text-red-400" /> : <FaPlay className="hover:text-blue-400" />}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          {/* </div> */}
          </ChatLineContainer>
          <audio ref={audioRef} />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

