import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useApp } from '../../hooks';
import { accept_join_request, get_all_pending_requests, get_user_invitation_link, reject_join_request, removeUserFromOrgService } from '../../services/organisation.service';
import EditUserInfoForm from './EditUserInfoForm';
import Tooltip from '@mui/material/Tooltip';
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { getUsersInfo } from '../../services/auth.service';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const queryClient: any = new QueryClient();

const fetchUsers = async (orgId: any, pagination: any): Promise<any> => {
  const response: any = await getUsersInfo(orgId, pagination.pageSize, pagination.pageIndex + 1);
  console.log("Response: ", response);
  return response.data;
};

const OrganisationUsers: React.FC = () => {
  const { state, dispatch }: any = useApp();
  const [activeTab, setActiveTab]: any = useState(0);
  const [pagination, setPagination]: any = useState({ pageIndex: 0, pageSize: 10 });
  const [editUserData, setEditUserData]: any = useState(null);
  const [isModalOpen, setIsModalOpen]: any = useState(false);
  const [isDialogOpen, setIsDialogOpen]: any = useState(false);
  const [userToDelete, setUserToDelete]: any = useState(null);

  const columns: any = useMemo(
    () => [
      {
        accessorKey: 'userInfo.display_name',
        header: 'Name',
        cell: (info: any) => info.getValue(),
      },
      {
        accessorKey: 'userInfo.email',
        header: 'Email',
        cell: (info: any) => info.getValue(),
      },
      {
        accessorKey: 'role',
        header: 'Role',
        cell: (info: any) => info.getValue().toUpperCase(),
      },
      {
        accessorKey: 'action',
        header: 'Action',
        cell: ({ row }: any) => (
          <div className='flex gap-2'>
            <Tooltip title="Delete User">
              <Button onClick={() => handleOpenDialog(row.original)}>
                <DeleteIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Edit User">
              <Button onClick={() => handleUserEdit(row.original)}>
                <EditIcon />
              </Button>
            </Tooltip>
          </div>
        ),
      },
    ],
    []
  );

  const dataQuery: any = useQuery({
    queryKey: ['users', pagination],
    queryFn: () => fetchUsers(state.selectedOrganisation._id, pagination),
  });

  const defaultData: any = useMemo(() => [], []);

  const table: any = useReactTable({
    data: dataQuery.data?.data ?? defaultData,
    columns,
    rowCount: dataQuery.data?.total,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const handleUserAccept = async (userData: any): Promise<void> => {
    const response: any = await accept_join_request(userData.user_id, state.selectedOrganisation._id);
    if (response.data.success === 1) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Accept User",
          message: "User accepted successfully",
          type: "success",
        },
      });
      const pendingRequests: any = await get_all_pending_requests(state.selectedOrganisation._id);
      if (pendingRequests.data.success === 1) {
        dispatch({ type: "SET_PENDING_REQUESTS", payload: pendingRequests.data.data });
      }
    } else {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Accept User",
          message: response.data.message,
          type: "error",
        },
      });
    }
  };

  const handleUserReject = async (userData: any): Promise<void> => {
    const response: any = await reject_join_request(userData.user_id, state.selectedOrganisation._id);
    if (response.data.success === 1) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Reject User",
          message: "User join request rejected",
          type: "success",
        },
      });
      const pendingRequests: any = await get_all_pending_requests(state.selectedOrganisation._id);
      if (pendingRequests.data.success === 1) {
        dispatch({ type: "SET_PENDING_REQUESTS", payload: pendingRequests.data.data });
      }
    } else {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Reject User",
          message: response.data.message,
          type: "error",
        },
      });
    }
  };

  const invitationColumns: any = [
    {
      name: "Name",
      sortable: true,
     selector: (row: any) => {
  console.log(row); // Print the row to the console
  return row?.user_info?.display_name ?? "";
},
    },
    {
      name: "Email",
      sortable: true,
      selector: (row: any) => row?.user_info?.email,
    },
    {
      name: "Requested On",
      sortable: true,
      selector: (row: any) => row?.requested_on,
    },
    {
      name: "Action",
      sortable: true,
      selector: (row: any) => (
        <div className='flex gap-4'>
          <div>
          <Tooltip title="Accept User">
            <Button onClick={() => handleUserAccept(row)}>
              <CheckCircleIcon />
            </Button>
          </Tooltip>
          </div>
          <div>
          <Tooltip title="Reject User">
            <Button onClick={() => handleUserReject(row)}>
              <CancelIcon />
            </Button>
          </Tooltip>
          </div>
          
        </div>
      ),
    },
  ];

  useEffect((): void => {
    const fetchPendingRequests = async (): Promise<void> => {
      const pendingRequests: any = await get_all_pending_requests(state.selectedOrganisation._id);
      console.log("Pending Requests: ", pendingRequests?.data?.data);
      if (pendingRequests.data.success === 1) {
        console.log("Pending Requests: ", pendingRequests.data.data);
        dispatch({ type: "SET_PENDING_REQUESTS", payload: pendingRequests.data.data });
      }
    };
    fetchPendingRequests();
  }, [state.selectedOrganisation._id]);

  const handleOpenDialog = (userData: any): void => {
    setUserToDelete(userData);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = (): void => {
    setIsDialogOpen(false);
    setUserToDelete(null);
  };

  const handleDeleteUser = async (): Promise<void> => {
    if (userToDelete) {
      await handleOrgUserDelete(userToDelete);
      handleCloseDialog();
    }
  };

  const handleOrgUserDelete = async (userData: any): Promise<void> => {
    try {
      if (userData.role === "owner") {
        dispatch({
          type: "SET_NOTIFICATION",
          payload: {
            show: true,
            title: "Remove User",
            message: "You cannot remove the owner",
            type: "error",
          },
        });
      } else {
        const { status, message }: any = await removeUserFromOrgService(userData.user_id, state.selectedOrganisation._id);
        if (status) {
          dispatch({
            type: "SHOW_MODAL",
            payload: { show: false, title: "", component: null, className: "" },
          });
          dispatch({
            type: "SET_NOTIFICATION",
            payload: {
              show: true,
              title: "Remove User",
              message: "User removed successfully",
              type: "success",
            },
          });
        } else {
          dispatch({
            type: "SET_NOTIFICATION",
            payload: {
              show: true,
              title: "Remove User",
              message: message,
              type: "error",
            },
          });
        }
      }
    } catch (error: any) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Remove User",
          message: "Something went wrong!",
          type: "error",
        },
      });
    }
  };

  const handleUserEdit = (userData: any): void => {
    setEditUserData(userData);
    setIsModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setIsModalOpen(false);
    setEditUserData(null);
  };

  const copyInviteLink = async (): Promise<void> => {
    const inviteLink: any = await get_user_invitation_link(state.selectedOrganisation._id);
    navigator.clipboard.writeText(inviteLink);
    dispatch({
      type: "SET_NOTIFICATION",
      payload: {
        show: true,
        title: "Copy Link",
        message: "Link copied to clipboard",
        type: "success",
      },
    });
  };

  const renderContent = (): any => {
    if (activeTab === 0) {
      return (
        <TableContainer component={Paper} elevation={3} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup: any) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header: any) => (
                    <TableCell
                      key={header.id}
                      colSpan={header.colSpan}
                      sx={{ fontWeight: "bold"}}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            
            <TableBody style={{ textAlign: "center", height: "75vh" }}>
              {dataQuery.isFetching ? (
                <TableRow>
                  <TableCell
                    colSpan={100}
                    style={{ textAlign: "center"}}
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                table.getRowModel().rows.map((row: any) => (
                  <TableRow key={row.id} hover>
                    {row.getVisibleCells().map((cell: any) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <div
            className="pagination"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "16px",
            }}
          >
            <LoadingButton
              loading={dataQuery.isFetching}
              variant="outlined"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<"}
            </LoadingButton>
            <span>
              Page {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </span>
            <LoadingButton
              loading={dataQuery.isFetching}
              variant="outlined"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {">"}
            </LoadingButton>
          </div>
        </TableContainer>
      );
    } else if (activeTab === 1) {
      return (
        <DataTable
          key="requests"
          columns={invitationColumns}
          data={state.pendingRequests}
          pagination={true}
        />
      );
    }
  };

  const handleTabChange = (event: any, newValue: any): void => {
    setActiveTab(newValue);
  };

  return (
    <div className="flex flex-col">
     <div className="flex items-center justify-between">
  <Tabs value={activeTab} onChange={handleTabChange}>
    <Tab label="Members" />
    <Tab label="Join Requests" />
  </Tabs>
  <div className="flex justify-end items-center space-x-2">
    <Button variant="contained" color="secondary" onClick={copyInviteLink}>
      <ContentCopyIcon sx={{ marginRight: 1 }} />
      Copy Invitation Link
    </Button>
  </div>
</div>
      {renderContent()}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="edit-user-modal-title"
        aria-describedby="edit-user-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          {editUserData && <EditUserInfoForm userData={editUserData} />}
        </Box>
      </Modal>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteUser} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const OrganisationUsersWithProvider: React.FC = () => (
  <QueryClientProvider client={queryClient}>
    <OrganisationUsers />
  </QueryClientProvider>
);

export default OrganisationUsersWithProvider;
