import { useOutlet } from "react-router-dom";
import ConformationModel from "../models/ConformationModel";
import NotificationModel from "../models/NotificationModel";
import { AppProvider } from "../providers";
import Header from "./components/Header"
import OrganisationCreateModel from "../components/organisation/OrganisationCreateModel";
// import DefaultModel from "../models/DefaultModel";
import Sidebar from "./components/Sidebar";
import ChatCategoryMenu from "./components/ChatCategoryMenu";
import AdminMenu from "./components/AdminMenu";
import { MdOutlineMenu } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Layout = () => {
  const outlet = useOutlet();

  return (
    <AppProvider>
      <ToastContainer />
      <div className="flex" id="model">
        {/* <div className="left-sidebar-section bg-gray-600" id="left-sidebar">
          <Sidebar>
            {window.location.pathname.includes('/thread/') ? <ChatCategoryMenu /> : <AdminMenu />} 
          </Sidebar>
        </div> */}
        <div className="flex flex-col w-full h-full">
          <div className="flex">
            {/* <div className="header-section flex shadow-md py-2 px-4 relative justify-between text-white">
              <button className="block x-sm:hidden" id="collapse" onClick={() => {
                const element: any = document.getElementById('left-sidebar')
                if(element.classList.contains('hidden')) {
                  element.classList.remove('hidden')
                } else {
                  element.classList.add('hidden')
                }
              }}>
                <MdOutlineMenu size={30} color="black" />
              </button>
            </div> */}
            <Header outlet={outlet}>
            {window.location.pathname.includes('/thread/') ? <ChatCategoryMenu /> : <AdminMenu />} 
            </Header>
          </div>
          {/* <div className="body-container">
            {outlet}
          </div> */}
        </div>
        <ConformationModel />
        <NotificationModel />
        <OrganisationCreateModel />
        {/* <DefaultModel /> */}
      </div>
    </AppProvider>
  )
}

export default Layout