import { useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { resetPasswordService } from "../services/auth.service";
import PrimaryLoader from "../components/shared/PrimaryLoader";

const PasswordResetPage = () => {
	const navigate = useNavigate()

	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState({ text: '', type: '' });

	const handleChange = (event: any) => {
		setLoading(false)
		setMessage({ text: '', type: '' })
		setEmail(event.target.value)
	}

	const handleForgotPassword = async () => {
		if (email) {
			try {
				setLoading(true);
				await resetPasswordService(email);
				setMessage({ text: 'Password reset link sent. Please check your email.', type: 'success' })
				setLoading(false);

				setTimeout(() => {
					navigate('/auth/login')
				}, 3000);
			} catch (error) {
				setLoading(false)
				setMessage({ text: 'User does not exist with this email!', type: 'error' });
			}
		} else {
			setLoading(false)
			setMessage({ text: 'Please enter email!', type: 'error' });
		}
	}

	return (
		<div className="container max-w-md mx-auto xl:max-w-3xl h-full flex bg-white overflow-hidden">
			<div className="w-full p-8">
				<form>
					<h1 className=" text-2xl font-bold">Reset your password</h1>
					<div>
						<span className="text-gray-600 text-sm"> Don't have an account?</span>
						<Link className="text-gray-700 text-sm font-semibold ml-2" to="/auth/register">Sign up</Link>
					</div>
					<div className="mb-4 mt-6">
						<label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="email">Email</label>
						<input
							className="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
							id="email"
							name="email"
							type="text"
							onChange={handleChange}
							placeholder="Your email address"
						/>
					</div>
					{
						message.text && (
							<div className="mb-6 mt-6">
								<span className={`${ message.type === 'error' ? 'text-red-600': 'text-green-800'}`}>{message.text}</span>
							</div>
						)

					}
					{
						loading && (
							<div>
								<PrimaryLoader />
							</div>
						)
					}
					<div className="flex w-full mt-8">
						<button
							className="w-full bg-gray-800 hover:bg-grey-900 text-white text-sm py-2 px-4 font-semibold rounded focus:outline-none focus:shadow-outline h-10"
							type="button"
							onClick={handleForgotPassword}
						>
							Send Reset Link
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default PasswordResetPage

