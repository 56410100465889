export const getIcon = (value: string, bgColor="#1f2937", color="#FFF") => {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
  svg.setAttribute("width", "100");
  svg.setAttribute("height", "100");

  const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
  circle.setAttribute("cx", "50");
  circle.setAttribute("cy", "50");
  circle.setAttribute("r", "50");
  circle.setAttribute("fill", bgColor);

  const text:any = document.createElementNS("http://www.w3.org/2000/svg", "text");
  text.setAttribute("x", "50");
  text.setAttribute("y", "50");
  text.setAttribute("font-size", "50");
  text.setAttribute("fill", color);
  text.setAttribute("text-anchor", "middle");
  text.setAttribute("alignment-baseline", "middle");
  text.textContent = value;

  svg.appendChild(circle);
  svg.appendChild(text);
  const svgData = new XMLSerializer().serializeToString(svg);
  const imageData = "data:image/svg+xml;base64," + btoa(svgData);
  return imageData;
}

export function highlightMessage(id:string){
  const elements: any = document.querySelectorAll('.message-list-box')
  for(const element of elements) {
    if(element.id === `bot-${id}`) {
      element.classList.add("border","border-solid","border-teal-500")
      element.scrollIntoView()
    } else {
      element.classList.remove("border","border-solid","border-teal-500")
    }
  }
}

export function highlightSpentMatrixRow( id: string ){
  const elements: any = document.querySelectorAll('.spent-matrix-list')
  for(const element of elements) {
    if(element.id === `bot-matrix-${id}`) {
      element.classList.add("bg-gray-700")
      element.scrollIntoView()
    } else {
      element.classList.remove("bg-gray-700")
    }
  }
}
