import { useState, useEffect } from "react";
import { useApp } from "../../hooks";
import { getCheckoutSession } from "../../services/payment.service";

import { motion } from 'framer-motion';

const TokenTopupForm = ({ model, type} : any) => {
  const { state, dispatch }: any = useApp()
  const [isMaxTokens, setIsMaxTokens] = useState(false);

  const getMinimumTokens = () => {
    switch (model) {
      case 'gpt-3.5':
        switch (type) {
          case 'input':
            return 1000000;
          case 'output':
            return 400000;
          default:
            return 0;
        }
      case 'gpt-3.5-turbo':
        switch (type) {
          case 'input':
            return 1000000;
          case 'output':
            return 400000;
          default:
            return 0;
        }
      case 'gpt-4':
        switch (type) {
          case 'input':
            return 50000;
          case 'output':
            return 25000;
          default:
            return 0;
        }
      case 'gpt-4-turbo':
        switch (type) {
          case 'input':
            return 100000;
          case 'output':
            return 50000;
          default:
            return 0;
        }
      case 'gpt-4o':
        switch (type) {
          case 'input':
            return 50000;
          case 'output':
            return 25000;
          default:
            return 0;
        }
      case 'gemini-1.5-pro':
        switch (type) {
          case 'input':
            return 50000;
          case 'output':
            return 25000;
          default:
            return 0;
        }
      case 'gemini-1.5-flash':
        switch (type) {
          case 'input':
            return 50000;
          case 'output':
            return 25000;
          default:
            return 0;
        }
      case 'claude-opus':
        switch (type) {
          case 'input':
            return 50000;
          case 'output':
            return 25000;
          default:
            return 0;
        }
      case 'tts-1':
        switch (type) {
          case 'limit':
            return 50000;
          case 'hd_limit':
            return 25000;
          default:
            return 0;
        }
      case 'whisper-1':
        switch (type) {
          case 'limit':
            return 50000;
          default:
            return 0;
        }
      default:
        return 0;
    }
  }

  const [parameters, setParameters] : any = useState({
    tokens: getMinimumTokens()
  });
  const [price, setPrice] = useState(0);

  useEffect(() => {
    setPrice(calculatePrice(parameters));
  }, [parameters]);

  const calculatePrice = (params: any) => {
    switch (model) {
      case 'gpt-3.5':
        switch (type) {
          case 'input':
            return params.tokens * 0.0000007;
          case 'output':
            return params.tokens * 0.0000021;
          default:
            return 0;
        }
      case 'gpt-3.5-turbo':
        switch (type) {
          case 'input':
            return params.tokens * 0.0000007;
          case 'output':
            return params.tokens * 0.0000021;
          default:
            return 0;
        }
      case 'gpt-4':
        switch (type) {
          case 'input':
            return params.tokens * 0.000042;
          case 'output':
            return params.tokens * 0.000084;
          default:
            return 0;
        }
      case 'gpt-4-turbo':
        switch (type) {
          case 'input':
            return params.tokens * 0.000014;
          case 'output':
            return params.tokens * 0.000042;
          default:
            return 0;
        }
      case 'gpt-4o':
        switch (type) {
          case 'input':
            return params.tokens * 0.000007;
          case 'output':
            return params.tokens * 0.000021;
          default:
            return 0;
        }
      case 'gemini-1.5-pro':
        switch (type) {
          case 'input':
            return params.tokens * 0.0000098;
          case 'output':
            return params.tokens * 0.0000294;
          default:
            return 0;
        }
      case 'gemini-1.5-flash':
        switch (type) {
          case 'input':
            return params.tokens * 0.00000098;
          case 'output':
            return params.tokens * 0.00000294;
          default:
            return 0;
        }
      case 'claude-opus':
        switch (type) {
          case 'input':
            return params.tokens * 0.000021;
          case 'output':
            return params.tokens * 0.000105;
          default:
            return 0;
        }
      case 'tts-1':
        switch (type) {
          case 'limit':
            return params.tokens *  0.000015;
          case 'hd_limit':
            return params.tokens * 0.00003;
          default:
            return 0;
        }
      case 'whisper-1':
        switch (type) {
          case 'limit':
            return params.tokens * 0.0001;
          default:
            return 0;
        }

      default:
        return 0;
    }
  };

  const handleOnPay = async () => {
      const payload = {
        organisationId: state.selectedOrganisation._id,
        model: model,
        type: type,
        units: parameters.tokens,
        successUrl: 'https://datalinker.ai/payment/success',
        cancelUrl: 'https://datalinker.ai/payment/cancel'
      }
      const response: any = await getCheckoutSession(payload)
      if (response) {
        dispatch({ type: 'SHOW_MODAL', payload: { show: false, title: '', component: null, className: '' } })
        window.open(response.url, '_blank', 'noreferrer');
      }
      else {
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: { show: true, title: 'Payment', message: 'Unable to process payment!', type: 'error' }
        })
      }
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      transition={{ duration: 0.5 }}
      className="p-8 rounded-lg shadow-lg"
    >
      <div className="text-center mb-6">
        <h2 className="text-2xl font-bold">Empower Your DatalinkerAI Experience</h2>
        <p >Purchase more tokens to continue enjoying seamless DatalinkerAI services. Our tokens provide you with the power to scale and adapt as your needs grow.</p>
      </div>
      <div className="flex flex-wrap gap-4">
        <div className="flex-1 min-w-[200px]">
          <label htmlFor="tokens" className="block text-lg font-semibold capitalize">{`${model} ${type} Tokens`}</label>
          <div className="relative">
            <input
              type="range"
              id="tokens"
              min={getMinimumTokens()}
              max={getMinimumTokens()*1000}
              step={5000}
              name="tokens"
              value={parameters.tokens}
              onChange={(e) => {
                const value = Math.round(parseFloat(e.target.value) / 5000) * 5000;
                setParameters({ ...parameters, tokens: value });
                setIsMaxTokens(value >= getMinimumTokens()*1000);
              }}
              className="w-full h-2 rounded-full cursor-pointer appearance-none"
              style={{
                background: `linear-gradient(to right, purple 0%, blue ${(parameters.tokens - getMinimumTokens()) / (getMinimumTokens() * 1000 - getMinimumTokens()) * 100}%, #ddd ${(parameters.tokens - getMinimumTokens()) / (1000000000 - getMinimumTokens()) * 100}%, #ddd 100%)`
              }}
            />
          </div>
        </div>
      </div>
      {isMaxTokens && (
        <p className="mt-4 text-lg font-semibold text-blue-600 animate-pulse">
          For more tokens, please contact the DatalinkerAI support.
        </p>
      )}
      <div className="mt-6">
        <p className="text-lg">Total Tokens: <span className="font-bold">{parameters.tokens}</span></p>
      </div>
      <div className="mt-6">
        <p className="text-lg">Total Price: <span className="font-bold">${price.toFixed(2)}</span></p>
      </div>
      <div className="mt-6">
        <motion.button 
          whileHover={{ scale: 1.05 }} 
          whileTap={{ scale: 0.95 }} 
          className="inline-flex items-center px-6 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gradient-to-br from-purple-400 to-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          onClick={handleOnPay}
        >
          Purchase Tokens
        </motion.button>
      </div>
      <div className="mt-6 text-center">
        <p className="text-red-600">Note: Tokens added as top up will not be carried over to the next month.</p>
      </div>
      <div className="mt-6 text-center">
        <p>Powered by The Virtulab</p>
      </div>
    </motion.div>
  );
}
export default TokenTopupForm;