import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks";
import { registerService } from "../services/auth.service";
import Button from "../components/shared/Button";

const RegisterPage = () => {
	const { auth, setAuth }: any = useAuth();
	const [user, setUser] = useState({
		email: '',
		password: '',
		displayName: ''
	})

	
    const [error, setError] = useState("")
    const [checked, setChecked] = useState(false);

    const handleRegister = async () => {
        if(user.email && user.password && user.displayName) {
            if (!checked) {
                setError('You must accept the Privacy Policy and Terms of Use and Terms of Service to create an account.');
                return;
            }
            const response:any = await registerService(user)
            if(response.status) {
                let { displayName, email, emailVerified, uid, idToken } = response?.data
                const userData = { uid: uid, email: email, displayName: displayName, emailVerified: emailVerified }
                window.localStorage.setItem('idToken', idToken)
                setAuth({ ...auth, user: userData })
                window.location.href = '/'
            }
            setError(response.messsage)
        } else {
            setError('Please ensure all fields are filled out to register!')
        }
    };

    const handleChange = (event: any) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value
        })
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

	return (
		<div className="container max-w-md mx-auto xl:max-w-3xl h-full flex bg-white overflow-hidden">
			<div className="w-full p-8">
				<form>
					<h1 className=" text-2xl font-bold">Create new account</h1>
					<div>
						<span className="text-gray-600 text-sm"> Already have an account?</span>
						<Link className="text-gray-700 text-sm font-semibold ml-2" to="/auth/login">Sign In</Link>
					</div>
					<div className="mb-4 mt-6">
						<label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="displayName">Name</label>
						<input
							className="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
							id="displayName"
							name="displayName"
							type="text"
							onChange={handleChange}
							placeholder="Your name"
						/>
					</div>
					<div className="mb-4 mt-6">
						<label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="email">Email</label>
						<input
							className="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
							id="email"
							name="email"
							type="text"
							onChange={handleChange}
							placeholder="Your email address"
						/>
					</div>
					<div className="mb-6 mt-6">
						<label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="password">Password</label>
						<input
							className="text-sm bg-gray-200 appearance-none rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline h-10"
							id="password"
							name="password"
							type="password"
							onChange={handleChange}
							placeholder="Your password"
						/>
					</div>
					<div className="mb-6 mt-6">
						{error && (<span className="text-red-600">{error}</span>)}
					</div>
					<div style={{margin: '20px 0', fontFamily: 'Arial, sans-serif'}}>
						<label style={{display: 'flex', alignItems: 'center'}}>
							<input
								type="checkbox"
								checked={checked}
								onChange={handleCheckboxChange}
								style={{marginRight: '10px'}}
							/>
							<span>
								I accept the &nbsp;
								<a href="https://docs.datalinker.ai/datalinker-terms-privacy-policy/privacy-policy" target="_blank" rel="noopener noreferrer" style={{color: '#007BFF', textDecoration: 'none'}}>
									Privacy Policy
								</a> 
								&nbsp; and &nbsp;
								<a href="https://docs.datalinker.ai/datalinker-terms-privacy-policy/terms-of-use" target="_blank" rel="noopener noreferrer" style={{color: '#007BFF', textDecoration: 'none'}}>
									Terms of Use
								</a>
								&nbsp; and &nbsp;
								<a href="https://docs.datalinker.ai/datalinker-terms-privacy-policy/terms-of-service" target="_blank" rel="noopener noreferrer" style={{color: '#007BFF', textDecoration: 'none'}}>
									Terms of Service.
								</a>
							</span>
						</label>
					</div>
					<div className="flex w-full mt-8">
    <Button 
        label="Register" 
        type="button" 
        onClick={handleRegister} 
        className="w-full rounded-lg bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 text-white font-semibold py-2 px-4 border border-transparent shadow-sm transition duration-200 ease-in-out"
    />
</div>
				</form>
			</div>
		</div>
	)
}

export default RegisterPage;

