import { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { useApp, useAuth } from '../../hooks';
import { changeEmail, updateDisplayName } from '../../services/auth.service';
import { TextField, Button, IconButton, Box, Typography, CircularProgress, useTheme, useMediaQuery } from '@mui/material';

const GeneralSettings = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { dispatch }: any = useApp()
  const { auth, setAuth }: any = useAuth();
  const [loading, setLoading] = useState(false);
  const [displayName, setDisplayName] = useState(auth?.user?.displayName || '');
  const [email, setEmail] = useState(auth?.user?.email || '');
  const [editField, setEditField] = useState('');

  const handleEditClick = (field: any) => {
    setEditField(editField === field ? '' : field);
  };

   const saveDisplayName = async () => {
        setLoading(true);
        if (displayName) {
          const response: any = await updateDisplayName(displayName);
          if (response.status) {
            setAuth({ user: { ...auth.user, displayName: response.data.displayName } });
            showMessage('success', 'Name updated successfully');
          } else {
            showMessage('error', 'Something went wrong!');
          }
        } else {
          showMessage('error', 'Please enter your name!');
        }
        setLoading(false);
        setEditField('');
      };
    
      const saveEmail = async () => {
        setLoading(true);
        if (email) {
          const response: any = await changeEmail(email);
          if (response.status) {
            setAuth({ user: { ...auth.user, email: response.data.email } });
            showMessage('success', 'Email updated successfully');
          } else {
            showMessage('error', response.message);
          }
        } else {
          showMessage('error', 'Please enter your email!');
        }
        setLoading(false);
        setEditField('');
      };
    

  const showMessage = (type: any, message: any) => {
    dispatch({ type: 'SET_NOTIFICATION', payload: { show: true, title: 'User Update', message, type } });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexDirection: isSmallScreen ? 'column' : 'row' }}>
        <Typography variant={isSmallScreen ? 'body1' : 'h6'}>{auth?.user?.displayName}</Typography>
        <IconButton onClick={() => handleEditClick('displayName')}>
          <MdEdit size={20} />
        </IconButton>
      </Box>
      {editField === 'displayName' && (
        <Box sx={{ display: 'flex', gap: 2, mb: 2, flexDirection: isSmallScreen ? 'column' : 'row' }}>
          <TextField
            label="Your name"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={saveDisplayName} disabled={loading} size={isSmallScreen ? 'small' : 'medium'}>
            {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
          <Button variant="outlined" onClick={() => setEditField('')} size={isSmallScreen ? 'small' : 'medium'}>
            Cancel
          </Button>
        </Box>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexDirection: isSmallScreen ? 'column' : 'row' }}>
        <Typography variant={isSmallScreen ? 'body1' : 'h6'}>{auth?.user?.email}</Typography>
        <IconButton onClick={() => handleEditClick('email')}>
          <MdEdit size={20} />
        </IconButton>
      </Box>
      {editField === 'email' && (
        <Box sx={{ display: 'flex', gap: 2, mb: 2, flexDirection: isSmallScreen ? 'column' : 'row' }}>
          <TextField
            label="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={saveEmail} disabled={loading} size={isSmallScreen ? 'small' : 'medium'}>
            {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
          <Button variant="outlined" onClick={() => setEditField('')} size={isSmallScreen ? 'small' : 'medium'}>
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default GeneralSettings;