import React, { useEffect, useMemo, useState } from 'react';
import { useApp } from "../../hooks";
import { MdOutlineDelete, MdOutlineEdit, MdRefresh } from "react-icons/md";
import { createDatabaseFromFileService, deleteDatabaseService, editDatabaseNameService, getDatabasesService } from "../../services/databases.service";
import EditDatabaseDialog from "./EditDatabaseModal";
import Spinner from "../shared/Spinner";
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  TableContainer, Table, Paper, TableRow, TableHead, TableCell, TableBody,
  Button, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  TableSortLabel,
  Pagination,
  Modal,
  Typography
} from '@mui/material';

const queryClient: any = new QueryClient();

const fetchDatabases = async (orgId: any): Promise<any> => {
  const response: any = await getDatabasesService(orgId, null, null, null, null);
  return response;
};

const DatabseList: React.FC<any> = ({ setTabs }: any) => {
  const { state, dispatch }: any = useApp();
  const [loadingMap, setLoadingMap]: any = useState({});
  const [isDialogOpen, setIsDialogOpen]: any = useState(false);
  const [databaseToDelete, setDatabaseToDelete]: any = useState(null);
  const [editDatabaseData, setEditDatabaseData]: any = useState(null);
  const [pagination, setPagination]: any = useState({ pageIndex: 0, pageSize: 10 });
  const [sortField, setSortField]: any = useState('created_at');
  const [sortOrder, setSortOrder]: any = useState('asc');

  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent]: any = useState(null);

  const setStatus = (message: any, type: any): void => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: { show: true, title: 'Drive Service', message: message, type: type }
    });
  };

  const { data, isFetching }: any = useQuery({
    queryKey: ['databases', state.selectedOrganisation._id],
    queryFn: () => fetchDatabases(state.selectedOrganisation._id),
  });

  useEffect((): void => {
    if (data) {
      console.log("Databases");
      console.log(data);
      dispatch({ type: 'SET_DATABASES', payload: data.response });
    }
  }, [data, dispatch]);

  const handleOpenDialog = (database: any): void => {
    setDatabaseToDelete(database);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = (): void => {
    setIsDialogOpen(false);
    setDatabaseToDelete(null);
  };

  const handleDeleteDatabase = async (): Promise<void> => {
    if (databaseToDelete) {
      await handleDatabaseDeleteConfirm(databaseToDelete.database_id);
      handleCloseDialog();
    }
  };

  const handleDatabaseDeleteConfirm = async (database_id: any): Promise<void> => {
    const { success, message }: any = await deleteDatabaseService(database_id, state.selectedOrganisation._id);
    if (success === 1) {
      setStatus("Drive deleted successfully", "success");
      const databases: any = await getDatabasesService(state.selectedOrganisation._id,  null, null, null, null);
      dispatch({ type: 'SET_DATABASES', payload: databases.response });
    } else {
      setStatus(message, "error");
    }
  };

  const handleOpenEditDialog = (database: any): void => {
    setEditDatabaseData(database);
    setModalTitle('Edit Database');
    setModalContent(
      <EditDatabaseDialog
        initialUsedVersion={database.version_in_use}
        initialVersion={database.version}
        databaseId={database.database_id}
        initialName={database.database_name}
        versions={database.versions}
        onConfirm={handleDatabaseUpdateConfirm}
        onCancel={handleCloseModal}
      />
    );
    setOpenModal(true);
  };

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPagination({ pageIndex: value, pageSize: 10 });
  };

  const handleCloseModal = (): void => {
    setOpenModal(false);
    setEditDatabaseData(null);
  };

  const handleDatabaseUpdateConfirm = async (database_id: any, database_name: any, database_version: any): Promise<void> => {
    const isUpdated: any = await editDatabaseNameService(database_id, database_name, state.selectedOrganisation._id, database_version);
    if (isUpdated) {
      setStatus('Drive has been successfully updated.', 'success');
      const databases: any = await getDatabasesService(state.selectedOrganisation._id,  null, null, null, null);
      dispatch({ type: 'SET_DATABASES', payload: databases.response });
    } else {
      setStatus('Drive update failed!', 'error');
    }
    handleCloseModal();
  };

  const handleRefresh = async (database: any): Promise<void> => {
    setLoadingMap({ ...loadingMap, [database.database_id]: true });
    dispatch({
      type: "SET_NOTIFICATION",
      payload: {
        show: true,
        title: "Drive Service",
        message: "The drive is being synced. Please wait...",
        type: "progress",
        toastId: database.database_id,
      },
    });
    try {
      const payload: any = {
        database_name: database.database_name,
        organisation_id: state.selectedOrganisation._id,
        is_file_upload: true,
        database_id: database.database_id,
        version: database?.version ?? null,
        file_ids: database?.file_ids ?? [],
        is_google_drive_file: database?.is_google_drive_file ?? false,
      };
      const { status, message }: any = await createDatabaseFromFileService(payload);
      if (status) {
        const databases: any = await getDatabasesService(state.selectedOrganisation._id,  null, null, null, null);
        dispatch({ type: 'SET_DATABASES', payload: databases.response });
        dispatch({
          type: "SET_NOTIFICATION",
          payload: {
            show: true,
            title: "Drive Service",
            message: "Drive has been successfully synchronised",
            type: "update",
            toastId: database.database_id,
            updateType: "success",
          },
        });
      } else {
        dispatch({
          type: "SET_NOTIFICATION",
          payload: {
            show: true,
            title: "Drive Service",
            message: "Unable to synchronise drive",
            type: "update",
            toastId: database.database_id,
            updateType: "error",
          },
        });
      }
    } catch (error: any) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Drive Service",
          message: "Unable to synchronise drive",
          type: "update",
          toastId: database.database_id,
          updateType: "error",
        },
      });
    } finally {
      setLoadingMap({ ...loadingMap, [database.database_id]: false });
    }
  };

  const handleSort = (field: any) => {
    const isAsc = sortField === field && sortOrder === 'asc';
    setSortField(field);
    setSortOrder(isAsc ? 'desc' : 'asc');
  };

  const sortedData = useMemo(() => {
    if (!state.databases) return [];
    let sorted = [...state.databases];
    if (sortField) {
      sorted.sort((a: any, b: any) => {
        if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    }
    return sorted;
  }, [state.databases, sortField, sortOrder]);

  const paginatedData = useMemo(() => {
    console.log("Pagination: ", pagination);
    const startIndex = pagination.pageIndex * pagination.pageSize;
    const endIndex = startIndex + pagination.pageSize;
    console.log("Sorted Data: ", sortedData);
    return sortedData.slice(startIndex, endIndex);
  }, [sortedData, pagination.pageIndex, pagination.pageSize]);

  const columns: any = useMemo(() => [
    {
      accessorKey: 'database_name',
      header: 'Drive Name',
      cell: (info: any) => {
        console.log(info); // This will log the value of info to the console
        return info.row.database_name;
      },
    },
    {
      accessorKey: 'version_in_use',
      header: 'Version',
      cell: (info: any) => info.row.version_in_use ?? 'N/A',
    },
    {
      accessorKey: 'created_at',
      header: 'Created At',
      cell: (info: any) => new Date(info.row.created_at).toLocaleString(),
    },
    {
      accessorKey: 'updated_at',
      header: 'Updated At',
      cell: (info: any) => new Date(info.row.updated_at).toLocaleString(),
    },
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: ({ row }: any) => (
        <div className="flex items-center justify-center space-x-2 py-2">
          {row?.is_google_drive_file && (
            <>
              {loadingMap[row.database_id] ? (
                <Spinner />
              ) : (
                <Button onClick={() => handleRefresh(row)} color="primary">
                  <MdRefresh size={20} />
                </Button>
              )}
            </>
          )}
          <Button onClick={() => handleOpenEditDialog(row)} color="primary">
            <MdOutlineEdit size={20} />
          </Button>
          <Button onClick={() => handleOpenDialog(row)} color="secondary">
            <MdOutlineDelete size={20} />
          </Button>
        </div>
      ),
    },
  ], [loadingMap]);

  return (
    <div className="flex flex-col" style={{ maxHeight: "75dvh", overflow: "auto" }}>
      <TableContainer component={Paper} elevation={3} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col: any) => (
                <TableCell
                  key={col.accessorKey}
                  sx={{
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center", 
                  }}
                  sortDirection={
                    sortField === col.accessorKey ? sortOrder : false
                  }
                >
                  <TableSortLabel
                    active={sortField === col.accessorKey}
                    direction={
                      sortField === col.accessorKey ? sortOrder : "asc"
                    }
                    onClick={() => handleSort(col.accessorKey)}
                  >
                    {col.header}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ height: "75vh" }}>
            {isFetching ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  style={{ textAlign: "center" }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              paginatedData.map((row: any, idx: any) => (
                <TableRow key={idx}>
                  {columns.map((col: any) => (
                    <TableCell key={col.accessorKey}>
                      {col.cell ? col.cell({ row }) : row[col.accessorKey]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex justify-center mt-2">
        <Pagination
          count={Math.floor(state.databases?.length / pagination.pageSize)}
          page={pagination.pageIndex}
          onChange={handlePaginationChange}
        />
      </div>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this drive? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteDatabase} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: '70%',
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {modalTitle}
          </Typography>
          <Box id="modal-description" sx={{ mt: 2 }}>
            {modalContent}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

const DatabseListWithProvider: React.FC<any> = () => (
  <QueryClientProvider client={queryClient}>
    <DatabseList />
  </QueryClientProvider>
);

export default DatabseListWithProvider;
