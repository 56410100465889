import { useState } from "react";
import ChatContentForm from "./ChatContentForm";

const AddChatCategory = ({setTabs}: any) => {

  const [tab, setTab] = useState({
    component: <ChatContentForm setTabs={setTabs}/>
  })

  return (
    <div className="w-full" style={{ maxHeight: "75dvh", overflowY: "auto" }}>

      {/* <div>
        <div className="flex justify-between items-center gap-4">
          <button className="w-full font-bold" onClick={() => {setTab({component: <CreateChatCategory />})}}>Create Using Form</button>
          <hr className="w-full"/>
          <button>OR</button>
          <hr className="w-full"/>
          <button className="w-full font-bold" onClick={() => {setTab({component: <ChatContentForm />})}}>Create Using file </button>
        </div>
      </div> */}

      <div >
        {tab.component}
      </div>
     
    </div>
  )
}

export default AddChatCategory