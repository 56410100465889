import { FaDatabase, FaComments, FaUsers } from 'react-icons/fa';
import { MdOutlineCategory } from 'react-icons/md';
import { motion } from 'framer-motion';
import './LandingPage.css'; 
import { loadSlim } from "tsparticles-slim";
import Particles from "react-tsparticles";
import { useNavigate, useOutlet } from 'react-router-dom';
import { useCallback } from 'react';
import logo from '../assets/logos/logo.png';

const LandingPage = () => {

    const navigate = useNavigate();

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.2, delayChildren: 0.3 } },
    };

    const outlet = useOutlet();
    const particlesInit = useCallback(async (engine: any) => {
        console.log(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: any) => {
        await console.log(container);
    }, []);

    const handleGetStarted = () => {
        navigate('/auth/login');
    }

    return (
        <div>
            <Particles
  id="tsparticles"
  init={particlesInit}
  loaded={particlesLoaded}
  options={{
    background: {
        color: {
            value: "#001219",
        },
    },
    fpsLimit: 60,
    interactivity: {
        events: {
            onClick: {
                enable: true,
                mode: "repulse",
            },
            onHover: {
                enable: true,
                mode: "grab", 
            },
            resize: true,
        },
        modes: {
            repulse: {
                distance: 100, 
                duration: 0.8, 
            },
            bubble: {
                distance: 250,
                size: 12,
                duration: 1.5,
                opacity: 0.8, 
            },
        },
    },
    particles: {
        color: {
            value: ["#c70039", "#4361ee", "#fff"],
        },
        links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.4,
            width: 0.8,
        },
        move: {
            direction: "bottom",
            enable: true,
            outModes: {
                default: "out",
            },
            random: true,  
            speed: 1,  
            straight: false,
        },
        number: {
            density: {
                enable: true,
                area: 800,
            },
            value: 100,
        },
        opacity: {
            value: { min: 0.1, max: 0.6 }, 
        },
        shape: {
            type: "circle",
        },
        size: {
            value: { min: 1, max: 3 },
        },
    },
    detectRetina: true,
  }}
/>
            <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="landing-container"
        >

            <motion.div className="content-section" variants={itemVariants}  style={{ position: 'relative', zIndex: 1 }}>
                <motion.h1 className="landing-page-title">
                    Welcome to 
                </motion.h1>
                {/* <div>
                    <h1 className="landing-page-title bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-blue-400 animate-pulse"> DatalinkerAI</h1>
                </div> */}
                <motion.div className="logo">
                    <img src={logo} alt="Logo" />
                    <div className="absolute top-0 right-0 bg-red-500 text-white text-xs py-1 px-2 rounded-bl-md">
                        BETA
                    </div>
                </motion.div>
                <motion.div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                <FeatureSection 
                    icon={<FaDatabase className="feature-icon" />}
                    title="Connect and Create Drives"
                    description="Create and connect your databases or workspaces like Google Workspace. Use different language models to query your database."
                />
                <FeatureSection 
                    icon={<MdOutlineCategory className="feature-icon" />}
                    title="Custom Categories"
                    description="Create categories for different teams in your organization. Each category is made up of one or more databases with custom instructions for the language models."
                /> 
                <FeatureSection 
                    icon={<FaComments className="feature-icon" />}
                    title="Perform Actions"
                    description="Perform actions like sending emails, messages on your organization's messaging platform, or creating meetings on your calendar."
                />
                <FeatureSection 
                    icon={<FaUsers className="feature-icon" />}
                    title="Collaborative Threads"
                    description="Users can share threads to collaborate where every user will be able to collaborate in the thread using their choice of LLM models."
                />
                </motion.div>
                

                <motion.button 
                    className="primary-button mt-10 mb-10" 
                    variants={buttonVariants} 
                    whileHover="hover"
                    transition={{ type: 'spring', stiffness: 300 }}
                    onClick={handleGetStarted}
                >
                    Begin Your Journey with DatalinkerAI
                </motion.button>

                <motion.div className="footer">
                    <p className="footer-text">Copyright © 2024 &nbsp;<a href="https://thevirtulab.com/" target="_blank" rel="noopener noreferrer" className="link">The Virtulab</a>. All rights reserved.</p>
                    <p className="footer-link">
                        <a href="https://docs.datalinker.ai/datalinker-terms-privacy-policy/privacy-policy" target="_blank" rel="noopener noreferrer" className="link">
                            Privacy Policy
                        </a> 
                        &nbsp; and &nbsp;
                        <a href="https://docs.datalinker.ai/datalinker-terms-privacy-policy/terms-of-use" target="_blank" rel="noopener noreferrer" className="link">
                            Terms of Use
                        </a>
                        &nbsp; and &nbsp;
                        <a href="https://docs.datalinker.ai/datalinker-terms-privacy-policy/terms-of-service" target="_blank" rel="noopener noreferrer" className="link">
                            Terms of Service
                        </a>
                    </p>
                    <p className="footer-link">
                        <a href="https://datalinkerai.atlassian.net/servicedesk/customer/portal/1" target="_blank" rel="noopener noreferrer" className="link">
                            Contact Us
                        </a>
                    </p>
                </motion.div>
            </motion.div>
            
        </motion.div>
        
        </div>
        
    );
};

const buttonVariants: any = {
    hover: { scale: 1.1, boxShadow: "0px 0px 8px rgb(255,255,255)" },
};

const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 },
};

const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2, delayChildren: 0.3 } },
};

const FeatureSection = ({ icon, title, description }: any) => (
    <motion.div
    className="feature-section"
    variants={containerVariants}
    initial="hidden"
    animate="visible"
    whileHover={{ scale: 1.05 }}
    transition={{ type: 'spring', stiffness: 300 }}
  >
    <motion.div className="feature-card" variants={itemVariants}>
      {icon}
      <motion.h2 className="feature-title">
        {title}
      </motion.h2>
      <motion.p className="feature-description">
        {description}
      </motion.p>
    </motion.div>
  </motion.div>
);


export default LandingPage;
