import { useState } from "react";
import { useApp, useAuth } from "../../hooks";
import {
  createOrganisationService,
  getOrganisationService,
  updateOrganisationService,
  updateZapierTokenService,
} from "../../services/organisation.service";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  InputLabel,
  FormControl,
  FormHelperText,
  Input,
} from "@mui/material";
import { styled } from "@mui/system";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const OrganisationCreateForm = ({ action, payload, handleStatus }: any) => {
  const { dispatch, setUserData, user }: any = useApp();
  const { auth }: any = useAuth();

  const [organisation, setOrganisation] = useState({
    name: payload?.name ? payload.name : "",
    org_logo: "",
  });

  const [zapierToken, setZapierToken] = useState("");

  const onSave = async () => {
    if (organisation.name) {
      if (action === "create") {
        const response = await createOrganisationService(organisation);
        if (response) {
          showMessage("success", "Organisation Created Successfully");
          dispatch({ type: "IS_ORGANISATION_NOTEXIST", payload: false });
          setUserData();
        } else {
          showMessage("error", "Something went wrong!");
        }
      }
      if (action === "update") {
        const response = await updateOrganisationService({
          ...organisation,
          organisation_id: payload._id,
        });
        if (response) {
          const orgRes = await getOrganisationService(payload._id);
          dispatch({ type: "SET_ACTIVE_ORGANISATION", payload: orgRes });
          showMessage("success", "Updated Successfully");
          handleStatus();
        } else {
          showMessage("error", "Update failed!");
        }
      }
    } else {
      showMessage("error", "Please enter all fields!");
    }
  };

  const validateFile = (file: any) => {
    const MAX_FILE_SIZE = 2 * 1024 * 1024;
    const MAX_DIMENSION = 512;
    return new Promise((resolve, reject) => {
      if (file.size > MAX_FILE_SIZE) {
        reject("File size should not exceed 2MB");
      }

      const img = new Image();
      img.onload = function () {
        if (img.width > MAX_DIMENSION || img.height > MAX_DIMENSION) {
          reject("Image dimensions should not exceed 512x512 pixels");
        }
        resolve(true);
      };
      img.onerror = function () {
        reject("Invalid image file");
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const updateZapierToken = async () => {
    updateZapierTokenService(zapierToken, payload._id, auth.user.uid)
      .then((response: any) => response?.data)
      .then((data) => {
        if (data.success === 1) {
          showMessage("success", "Zapier Token Updated Successfully");
        } else {
          console.log("Failed:", data);
          showMessage("error", "Failed to update Zapier Token");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showMessage("error", "Failed to update Zapier Token");
      });
  };

  const showMessage = (type: any, message: any) => {
    dispatch({
      type: "SET_NOTIFICATION",
      payload: {
        show: true,
        title: "Organisation Create",
        message: message,
        type: type,
      },
    });
  };

  const handleOnChange = (event: any) => {
    if (event.target.value.length <= 100) {
      setOrganisation({ ...organisation, name: event.target.value });
    } else {
      showMessage("error", "Organisation name should not exceed 100 characters");
    }
  };

  const handleFileChange = async (event: any) => {
    console.log("Event:", event);
    const file = event.target.files[0];
    console.log("File:", file);
    try {
      await validateFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        let base64: any = reader.result?.toString().split(",")[1];
        console.log("Base64:", base64);
        setOrganisation({ ...organisation, org_logo: base64 });
      };
      reader.readAsDataURL(file); // Start reading the file as a data URL
    } catch (error) {
      setOrganisation({ ...organisation, org_logo: "" });
      showMessage("error", error);
    }
  };

  const handleZapierToken = (event: any) => {
    setZapierToken(event.target.value);
  };

  return (
    <Box sx={{ p: 3, borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h6" gutterBottom>
        {action === "create" ? "Create Organisation" : "Update Organisation"}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="name"
            label="Organisation Name"
            value={organisation.name}
            onChange={handleOnChange}
            variant="outlined"
            inputProps={{ maxLength: 100 }}
            helperText="Organisation name should not exceed 100 characters"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined">
          <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Upload Logo
              <VisuallyHiddenInput type="file" onChange={handleFileChange}
              accept="image/*"  />
            </Button>
            <FormHelperText>Max size: 2MB, Max dimensions: 512x512px</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3, gap: 2 }}>
        {action === "update" && (
          <Button variant="outlined" onClick={() => handleStatus()}>
            Cancel
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={onSave}>
          Save
        </Button>
      </Box>
      {/* Uncomment the following section if you want to include the Zapier Token update feature */}
      {/* <Box sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="zapierToken"
              label="Zapier Token"
              value={zapierToken}
              onChange={handleZapierToken}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
            <Button variant="contained" color="primary" onClick={updateZapierToken}>
              Update Zapier Token
            </Button>
          </Grid>
        </Grid>
      </Box> */}
    </Box>
  );
};

export default OrganisationCreateForm;
